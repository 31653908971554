import React from "react";

function NFTchatLogo() {
    return <pre className="Title">
      ███    ██ ███████ ████████     ██             ██  <br></br>
      ████   ██ ██         ██        ██           ██████<br></br>
      ██ ██  ██ █████      ██  █████ ██████ █████   ██  <br></br>
      ██  ██ ██ ██         ██ ██     ██  ██ ██  ██  ██  <br></br>
      ██   ████ ██         ██  █████ ██  ██ ███ ███ ████<br></br>                                                  
  </pre>;
}

export default NFTchatLogo;

