import React, { useState, useEffect } from "react";
import NFTchatLogo from './NFTchatLogo'
import WelcomeMsg from './WelcomeMsg'
import Toast from './Toast'
import Networks from './Networks'
import { plainTxtToHtml, calculateFontSize, drawCanvas } from "../previewUtils";
import { getCurrentNetwork } from "../Blockchains"
import ChatContractHelper from "../ChatContractHelper"
import { getAccountTokenIds } from "../messages"
import { log } from "../debugUtils"
import { uploadDataToIPFS } from "../ipfsUtils"
import SocialNetworks from './SocialNetworks';

const previewPromptMsg = `NFT preview 🔍`
const maxTxtLength = 300000
const V2 = false
const contractHelper = new ChatContractHelper()

function NFTchat() {
  const [
    state, setState,
  ] = useState({
    showButton: true,
    isSubscribed: true,
    nftImg: 'https://ipfs.infura.io/ipfs/QmQEDQcGyy3hHBs2TdB3tUjwifVZ9sR9Uvvb39kT5pj2Gi'
  })
  const [toast, showToast] = useState({
    showToast: false,
    toastMsg: '',
    isErrorToast: false,
  })

  useEffect(() => {
    prepareWeb3()
  }, []);

  const prepareWeb3 = async () => {
    const web3 = await contractHelper.loadWeb3(() => { verifySubscription() })
    if (!web3) {
      showToast({ showToast: true, toastMsg: 'Non-Web3 browser detected. Please use a Web3 compatible browser!', isErrorToast: true })
      setState({ isSubscribed: false, showButton: false })
    }
  }

  const verifySubscription = async () => {
    const currentNetwork = getCurrentNetwork()
    log(`Verirying suubscription for Network: ${JSON.stringify(currentNetwork)}`)

    if (currentNetwork) {
      const isSubscribed = contractHelper.getSubscription()
      const account = contractHelper.getAccount();

      if (V2) {
      const nftId = await getAccountTokenIds(account);
      log(`nftId: ${nftId}`)
      let tokeInfo
        const nftUri = await contractHelper.getNFTUri(nftId)

        const response = await fetch(nftUri)
        tokeInfo = await response.json()
        log(`tokeInfo: ${JSON.stringify(tokeInfo)}`)
        setState({
          isSubscribed: isSubscribed,
          showButton: true,
          nftImg: tokeInfo.image
        })
      } else {
        setState({
          isSubscribed: isSubscribed,
          showButton: true,
        })
      }
    } else {
      showToast({ showToast: true, toastMsg: 'Unsupported Network, please change blockchain on your wallet', isErrorToast: true })
    }
  }

  const onSubscribe = async (e) => {
    log(`Subscribing address ${contractHelper.getAccount()}`)
    disableButton()
    try {
      await contractHelper.subscribeAddress()
      showToast({ showToast: true, toastMsg: "Subscription completed!", isErrorToast: false })
      setState({ isSubscribed: true, showButton: true })
    } catch (e) {
      log(`Subscription failed: ${e}`, 'error')
      showErrorToast(e)
    } finally {
      enableButton()
    }
  }

  const onTxtChanged = async (e) => {
    const textArea = e.target;
    const htmlText = plainTxtToHtml(textArea.value)

    let msgPreview = document.getElementById('msgPreview');
    msgPreview.innerHTML = htmlText.length > 0 ? htmlText : previewPromptMsg;

    const fontSize = calculateFontSize(msgPreview)
    msgPreview.style.fontSize = `${fontSize}px`
  }

  const onSend = async (e) => {
    const toAddress = document.getElementById('toAddress').value;
    if (contractHelper.validateAddress(toAddress)) {
      disableButton()
      const msgPreview = document.getElementById('msgPreview')
      const canvas = await drawCanvas(msgPreview)
      const fileUrl = await uploadDataToIPFS(canvas.toDataURL("image/png"), contractHelper.getAccount())
      await mintNFTchat(fileUrl, toAddress);
    } else {
      console.log("Invalid receiver address!")
      showToast({ showToast: true, toastMsg: "Invalid receiver address!", isErrorToast: true })
    }
  }

  async function mintNFTchat(fileUrl, toAddress) {
    try {
      await contractHelper.mintNFT(fileUrl, toAddress);
      showToast({ showToast: true, toastMsg: "NFT message sent!", isErrorToast: false });
    } catch (e) {
      log(`Transaction Error: ${e}`, 'error');
      showErrorToast(e);
    } finally {
      enableButton();
    }
  }

  const showErrorToast = (e) => {
    if (e.code === 4001) {
      showToast({ showToast: true, toastMsg: e.message, isErrorToast: true })
    } else {
      showToast({ showToast: true, toastMsg: "Transaction failed, please check your wallet balance", isErrorToast: true })
    }
  }

  const enableButton = async () => {
    const myButton = document.getElementById('myButton')
    myButton.setAttribute("class", "SendButton")
    myButton.disabled = false
  }

  const disableButton = async () => {
    const myButton = document.getElementById('myButton')
    myButton.setAttribute("class", "gradiend")
    myButton.disabled = true
    return myButton
  }

  let FromAddress, ToAddress, TextArea, MsgPreview, Button;

  if (state.isSubscribed) {
    log(`state.isSubscribed: ${state.isSubscribed}`)
    FromAddress = <div><b>From(you)</b>: {contractHelper.getAccount()}</div>
    ToAddress = <div><b>To(address)</b>: <input id="toAddress" maxLength="42" spellCheck="false" placeholder="Paste here the receiver address..."></input></div>
    TextArea = <textarea /*autoFocus*/ className="TextArea" id="the_text" maxLength={maxTxtLength} spellCheck="false" placeholder="Write your message here..." onChange={onTxtChanged} />
    MsgPreview = <div id="previewContainer"><div id="msgPreview">{previewPromptMsg}</div></div>
    if (state.showButton) {
      Button = <button id='myButton' className="SendButton" onClick={onSend}>Send</button>
    }
  } else {
    MsgPreview = <WelcomeMsg></WelcomeMsg>
    if (state.showButton) {
      Button = <button id='myButton' className="SubscribeButton" onClick={onSubscribe}>Subscribe</button>
    }
  }

  const styles = {
    width: "1019px",
    height: "1019px",
    minHeight: '1019px',
    transform: `scale(0.5)`
  };
  const myIframe = <iframe src={state.nftImg} style={styles}></iframe>

  //TODO move this from here
  if (toast.showToast) {
    setTimeout(() => { showToast({ showToast: false }) }, 5555);
  }

  document.documentElement.style.setProperty('--main-color', '#00fd00');
  document.documentElement.style.setProperty('--second-color', 'black');
  document.documentElement.style.setProperty('--shadow-color', '#c8c8c8');

  return (
    <div className="App">
      <NFTchatLogo></NFTchatLogo>
      <Networks></Networks>
      {MsgPreview}
      <Toast msg={toast.toastMsg} show={toast.showToast} isError={toast.isErrorToast}></Toast>
      {TextArea}
      <div className="container">
        <div className="clientInfo">
          {FromAddress}
          {ToAddress}
        </div>
        {Button}
      </div>
      <div><a style={{margin:0, padding:1}} href="https://nftchat.xyz/ads" target="_blank" rel="noreferrer">If you wanna send several messages to many NFT lovers please visit 👉🏻 Ads</a></div>
      <SocialNetworks></SocialNetworks>
      {/*{myIframe}*/}
      <div className="version">{process.env.REACT_APP_VERSION}</div>
    </div>
  )
}

export default NFTchat
