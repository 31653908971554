import React, { useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { log } from "../debugUtils"
import { retrieveCollections } from "../collections/collections.mjs";
import { setHolders, getMaxAddressNumber } from "../holders";
import { getCurrentNetwork } from "../Blockchains"

const customValueRenderer = (selected, _options) => {
  if (selected.length) {
    const reducer = (acc, curr) => {
      return acc + curr.holders
    };
    const total = selected.reduce(reducer, 0)
    
    let list = ''
    selected.forEach(({ value }) => list += ' ' + value + ',')

    list = list.slice(0, -1);
    return list + ' Total:' + total
  }
  else {
    return "Select Target Collections..."
  }
}

const Collections = () => {
  const [selected, setSelected] = useState([]);
  const [totalTargetAddrs, setTotalTargetAddrs] = useState(0);

  const getOptions = () => {
    const options = retrieveCollections().map(collection => {
      return { key: `${collection.filename}`, label: `${collection.name}  -  [${collection.blockchain}  ${collection.holders} holders]`, value: collection.name, filename: collection.filename, holders: collection.holders }
    })

    return options
  }
  
  const countTargetHolders = () => {
    let sumTo = 0
    let sumFrom = 0
    const selectedHolders = [];
    const currentNetwork = getCurrentNetwork().name

    log("Collections CURRENT NETWORK NAME: " + currentNetwork )
    const collections = document.getElementsByClassName("fromToCointainer")

    Array.from(collections).forEach(col => {
      const selectedCol = retrieveCollections().find(collection => collection.name == col.id)
      const fileName = selectedCol.filename
      log('Collection holders fileName: ' +  fileName)

      let collectionHolders = {'fileName': fileName, 'from':0, 'to':0}
      const from = document.getElementById('from' + col.id).value
      const to = document.getElementById('to' + col.id).value

      if (from !== null && from !== '') {
        sumFrom += parseInt(from)
          collectionHolders.from = from
      }

      if (to !== null && to !== '') {
        sumTo += parseInt(to)
          collectionHolders.to = to
      }

      selectedHolders.push(collectionHolders);
    })

    //log('SELECTED HOLDERS: ' +  JSON.stringify(selectedHolders))
    setHolders(selectedHolders)
    setTotalTargetAddrs(sumTo - sumFrom)
  }

  const totalTargetLabel = <div className="totalTarget">Total target addresses (Max 500): { totalTargetAddrs > getMaxAddressNumber() || totalTargetAddrs < 0 ? <b style={{color: '#ff5400'}}>{totalTargetAddrs}</b> : <b>{totalTargetAddrs}</b>}</div>

  return (
    <div>
      <MultiSelect
        options={getOptions}
        value={selected}
        hasSelectAll={false}
        onChange={setSelected}
        shouldToggleOnHover={false}
        labelledBy="Select"
        className="collections"
        valueRenderer={customValueRenderer}
      />
      {selected.map(sel => {
        return (
          <div className="fromToCointainer" id={sel.value}>
            <div><b>{sel.value}</b> ({sel.holders} holders)</div>
            <div id='fromToDiv'>
              From:<input className="fromTo" id={'from' + sel.value} type="number" placeholder="0" min="0" max="10000" onChange={countTargetHolders}></input> To:<input className="fromTo" id={'to' + sel.value} type="number" placeholder="0" min="0" max="10000" onChange={countTargetHolders}></input>
            </div>
          </div>
        )
      })}      
      {totalTargetLabel}
    </div>
  );
};

export default Collections;

