import { log } from "./debugUtils"
import { getCurrentNetwork } from "./Blockchains"
import ContractHelper from "./ContractHelper"
import { NFTchatABI } from "./contracts/ABIs/NFTChatABI"
export default class ChatContractHelper extends ContractHelper {

    constructor() {
        super(NFTchatABI)
        this.isSubscribed = true
    }

    onContractInitialized = async() => {
        log(`ContractHelper - Checking subscription for account: ${this.account}`)
        this.isSubscribed = await this.contract.methods.subscribedAddresses(this.account.toString()).call()
        log(`ContractHelper - Is Address subscribed? ${this.isSubscribed}`)
        return this.isSubscribed
    }

    getSubscription = () => {
        return this.isSubscribed;
    }

    getContractAddress = () => { return getCurrentNetwork().NFTchatAddress }

    getSubscriptionFee = async() => {
        const subscriptionFeeWei = await this.contract.methods.subscriptionFee().call()
        log(`ContractHelper - Subscription Fee from Contract: ${subscriptionFeeWei}`)
        return subscriptionFeeWei
    }

    getSubscriptionFeeInNativeToken = async() => {
        const subscriptionFeeWei = await this.contract.methods.subscriptionFee().call()
        const subscriptionFee = this.web3.utils.fromWei(subscriptionFeeWei, 'ether');
        log(`Current subscription fee in ${getCurrentNetwork().token}: ${subscriptionFee}`)
        return subscriptionFee
    }

    subscribeAddress = async() => {
        const currentSubsFee = await this.getSubscriptionFee()
        const value = this.web3.utils.toHex(currentSubsFee);

        const tx = {
            'from': this.account.toString(),
            'to': this.contractAdress,
            'value': value,
            maxPriorityFeePerGas: null,
            maxFeePerGas: null, 
        };

        const result = await this.contract.methods.subscribeAddress().send(tx)
        log(`Subscription TX ${JSON.stringify(result)}`)
        return result.status;
    }

    mintNFT = async(fileURI, toAddress) => {
        const mintFee = getCurrentNetwork().fee //get it from contract
        const amount = this.web3.utils.toWei(mintFee, 'ether');
        const value = this.web3.utils.toHex(amount);

        const tx = {
            'from': this.account.toString(),
            'to': this.getContractAddress(),
            'value': value,
            maxPriorityFeePerGas: null,
            maxFeePerGas: null, 
        };
        return await this.contract.methods.mint(toAddress, fileURI).send(tx)
    }

    getNFTUri = async(id) => {
        log(`token_id: ${id}`)
        const tokenURI = await this.contract.methods.tokenURI(id).call()
        log(`tokenURI: ${tokenURI}`)
        return tokenURI
    }

}