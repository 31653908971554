import React, { useState, useEffect } from "react";
import { getNetworks, getCurrentNetwork } from "../Blockchains"
import { log } from "../debugUtils"

const supportedNetworks = getNetworks();

const networkStyle = { color: 'var(--third-color)', fontFamily: 'monospace', padding: '1%' };
const currentStyle = { color: 'var(--main-color)', fontFamily: 'monospace', padding: '1%', fontWeight: 'bold'};

const containerStyle = {
  display: 'flex',
  justifyContent: 'space-evenly',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginTop: '2vmin',
  marginBottom: '2vmin',
  width: '86%',
  maxWidth: '650px',
  fontSize: '2vmin'
}

function Networks() {
  const [current, setCurrent] = useState({})

  useEffect(() => {
    setCurrent(getCurrentNetwork())
  });

  let networkNames = [];
  supportedNetworks.forEach(network => { networkNames.push(network.name) })
  log("About to render Networks...")
  return (
    <div style={containerStyle}>
      {
        networkNames.map(name => {
          if (current && name === current.name) {
            return <span key={name} style={currentStyle}>{name}</span>
          } else {
            return <span key={name} style={networkStyle}>{name}</span>
          }
        })
      }
    </div>
  )
}

export default Networks;


