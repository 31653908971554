const DEBUG = process.env.REACT_APP_DEBUG === 'true';

export function log(message, type) {
    if (DEBUG) {
        if (type === 'error') {
            console.error(message)
        }
        if (type === 'warn' || type === 'warning') {
            return console.warn(message)
        }
        return console.log(message)
    }
}