import { log } from "./debugUtils"
import html2canvas from 'html2canvas'

const OFFSET_MULTIPLIER = 1.54
const FONTSIZE_DIVIDEND = 8.3;
const ROW_COL_RATIO = 2.222;

export const drawCanvas = async(msgPreview) => {
    const NFTchatImgSize = 1000;
    const previewHeight = msgPreview.offsetHeight
    const scale = NFTchatImgSize / previewHeight
    log(`Scale: ${scale}`)

    const canvas = await html2canvas(msgPreview, {
        scale: scale,
        backgroundColor: 'black',
        width: previewHeight,
        height: previewHeight
    })
    return canvas
}

export function longestString(lines) {
    const longest = lines.reduce(
        (a, b) => {
            const plainTxtA = a.replaceAll('&nbsp;', ' ')
            const plainTxtB = b.replaceAll('&nbsp;', ' ')
            return plainTxtA.length > plainTxtB.length ? plainTxtA : plainTxtB;
        }
    );
    return longest.replaceAll('&nbsp;', ' ')
}

export function plainTxtToHtml(theText) {
    const br = '<br>'
    let htmlTxt = theText
        .replace(/\t/g, '    ')
        .replace(/ {2}/g, '&nbsp; ')
        .replace(/ {2}/g, ' &nbsp;')
        .replace(/\n\r?/g, br)
    return htmlTxt
}

export function calculateFontSize(msgPreview) {
    const offset = msgPreview.offsetHeight * OFFSET_MULTIPLIER
    log(`offset ${offset}`)

    const html = msgPreview.innerHTML;
    let ratio = getRatio(html);

    return getFontSize(offset, ratio);
}


function getRatio(html) {
    const rows = html.split("<br>");
    const cols = longestString(rows);
    log(`longestString(rows):           ${cols}`);
    const rowTimesRatio = rows.length * ROW_COL_RATIO;
    const colLength = cols.length;
    let ratio = rowTimesRatio > colLength ? rowTimesRatio : colLength;

    log(`Inner HTML: ${html}`);
    log(`rows:           ${rows.length}`);
    log(`cols:           ${cols.length}`);
    log(`rowTimesRatio:  ${rowTimesRatio}`);
    log(`longest col length:  ${colLength}`);
    log(`ratio:  ${ratio}`);

    return ratio;
}

function getFontSize(offset, ratio) {
    const maxFontSize = offset / FONTSIZE_DIVIDEND;
    const minFontSize = 0.5; //px

    let fontSize = offset / ratio;

    if (fontSize > maxFontSize) {
        log(`Calculated fontSize:${fontSize} exceeds maxFontSize, I will use maxFontSize: ${maxFontSize}`);
        fontSize = maxFontSize;
    } else if (fontSize < minFontSize) {
        log(`Calculated fontSize:${fontSize} is less than minFontSize, I will use minFontSize: ${minFontSize}`);
        fontSize = minFontSize;
    }

    log(`Calculated fontSize: ${fontSize}`);
    //return Math.floor(fontSize);
    return fontSize;
}