import './App.css'
import NFTchat from './components/NFTchat';
import Ads from './components/Ads';
import Visualizer from './components/Visualizer';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';

function App() {
 
  return (
    <Router>
     {/* <div className="App"> */}
      <Routes>
        <Route path='/' element={<NFTchat/>}/>
        <Route path='/ads' element={<Ads/>}/>
        <Route path='/view' element={<Visualizer/>}/>
      </Routes>
    {/* </div> */}
    </Router>
  )
}

export default App
