export function getMetaData(imgUrl, senderAdress, birthday) {
    return {
        "name": "NFTchat",
        "description": `${birthday} \n
You received this message from: ${senderAdress} \n
To reply visit [NFTchat.xyz](https://nftchat.xyz)`,
        "external_link": "https://nftchat.xyz",
        "image": `${imgUrl}`,
        "attributes": [{
            "display_type": "date",
            "trait_type": "Message Date",
            "value": `${birthday}`
        }]
    }
}