import * as Polygon_CryptoChillouts from './Polygon_CryptoChillouts';
import * as Ethereum_DivineAnarchy from './Ethereum_DivineAnarchy';
import * as BSC_AlpiesBSC from './BSC_AlpiesBSC';
import * as BSC_ArtexNFT from './BSC_ArtexNFT';
import * as BSC_BabyBadasses from './BSC_BabyBadasses';
import * as Polygon_Cryptofarmeur from './Polygon_Cryptofarmeur';
import * as Ethereum_DogsofElon from './Ethereum_DogsofElon';
import * as BSC_BinanceBullSoc from './BSC_BinanceBullSoc';
import * as BSC_BINOhouse from './BSC_BINOhouse';
import * as BSC_BlocksportNFT from './BSC_BlocksportNFT';
import * as Polygon_DecentralGamesICEPoker from './Polygon_DecentralGamesICEPoker';
import * as Ethereum_Doodles from './Ethereum_Doodles';
import * as BSC_BlocksportNFTTickets from './BSC_BlocksportNFTTickets';
import * as BSC_BNBPunks from './BSC_BNBPunks';
import * as BSC_BornBadBoys from './BSC_BornBadBoys';
import * as Polygon_DeRaceHorses from './Polygon_DeRaceHorses';
import * as Ethereum_JayPegsAutoMart from './Ethereum_JayPegsAutoMart';
import * as BSC_BornBadGirls from './BSC_BornBadGirls';
import * as BSC_BSCLoot from './BSC_BSCLoot';
import * as BSC_CactiClub from './BSC_CactiClub';
import * as Polygon_DeRaceJockeyClub from './Polygon_DeRaceJockeyClub';
import * as Ethereum_LoneleyAliensSpaceClub from './Ethereum_LoneleyAliensSpaceClub';
import * as BSC_CartelPunks from './BSC_CartelPunks';
import * as BSC_DeRaceTicket from './BSC_DeRaceTicket';
import * as BSC_DogePunks from './BSC_DogePunks';
import * as Polygon_EETHs from './Polygon_EETHs';
import * as Ethereum_MutantApeYachtClub from './Ethereum_MutantApeYachtClub';
import * as BSC_EIGHTBITFUN from './BSC_EIGHTBITFUN';
import * as BSC_FrostyGenesis from './BSC_FrostyGenesis';
import * as BSC_FungieColors from './BSC_FungieColors';
import * as Polygon_EmberSwordBadge from './Polygon_EmberSwordBadge';
import * as Ethereum_NFTradeBreakpointParty from './Ethereum_NFTradeBreakpointParty';
import * as BSC_GamerNFLs from './BSC_GamerNFLs';
import * as BSC_GangsterPunks from './BSC_GangsterPunks';
import * as BSC_HatSwap from './BSC_HatSwap';
import * as Polygon_EmberSwordLand from './Polygon_EmberSwordLand';
import * as Ethereum_PartyApes from './Ethereum_PartyApes';
import * as BSC_Opulous from './BSC_Opulous';
import * as BSC_PipemoonCapsule from './BSC_PipemoonCapsule';
import * as BSC_PokelonEggs from './BSC_PokelonEggs';
import * as Polygon_EvolutionLandObjects from './Polygon_EvolutionLandObjects';
import * as Ethereum_PolychainMonsters from './Ethereum_PolychainMonsters';
import * as BSC_PolychainMonsters from './BSC_PolychainMonsters';
import * as BSC_Poopies from './BSC_Poopies';
import * as BSC_PrelaxNFT from './BSC_PrelaxNFT';
import * as Polygon_Fear from './Polygon_Fear';
import * as Ethereum_ProofofSteak from './Ethereum_ProofofSteak';
import * as BSC_REALM from './BSC_REALM';
import * as BSC_StarNFTProxyAlpacaFinance from './BSC_StarNFTProxyAlpacaFinance';
import * as BSC_StarryNift from './BSC_StarryNift';
import * as Polygon_LeagueofKingdomsITEM from './Polygon_LeagueofKingdomsITEM';
import * as Ethereum_SadFrogsDistrict from './Ethereum_SadFrogsDistrict';
import * as BSC_TheDJTCollection from './BSC_TheDJTCollection';
import * as BSC_TheFOMOChronicles from './BSC_TheFOMOChronicles';
import * as BSC_TokAuStarryToken from './BSC_TokAuStarryToken';
import * as Polygon_MoonieNFTOfficialCollection from './Polygon_MoonieNFTOfficialCollection';
import * as Ethereum_SipherianFlash from './Ethereum_SipherianFlash';
import * as BSC_TTKHero from './BSC_TTKHero';
import * as BSC_TurtlePunks from './BSC_TurtlePunks';
import * as BSC_Warship from './BSC_Warship';
import * as Polygon_NeonDistrictSeasonOneItem from './Polygon_NeonDistrictSeasonOneItem';
import * as Ethereum_StarlinkPixelNauts from './Ethereum_StarlinkPixelNauts';
import * as BSC_WeAreSatoshi from './BSC_WeAreSatoshi';
import * as BSC_Weapon from './BSC_Weapon';
import * as BSC_XCGenesis_N from './BSC_XCGenesis_N';
import * as Ethereum_XCADNFT from './Ethereum_XCADNFT';
import * as BSC_XCGenesis_R from './BSC_XCGenesis_R';
import * as BSC_XCGenesis_S from './BSC_XCGenesis_S';
import * as BSC_XCGenesis_SR from './BSC_XCGenesis_SR';
import * as Polygon_PolygonPunks from './Polygon_PolygonPunks';
import * as Ethereum_THEREALGOATSOCIETY from './Ethereum_THEREALGOATSOCIETY';
import * as BSC_XCGenesis_SSR from './BSC_XCGenesis_SSR';
import * as BSC_YieldProtocol from './BSC_YieldProtocol';
import * as BSC_ZecreyChameleonAvatar from './BSC_ZecreyChameleonAvatar';
import * as Polygon_rabbitholegg from './Polygon_rabbitholegg';
import * as Ethereum_Alpies from './Ethereum_Alpies';
import * as Ethereum_ArtBlocks from './Ethereum_ArtBlocks';
import * as Polygon_REVVRacing from './Polygon_REVVRacing';
import * as Ethereum_BASTARDGANPUNKSV2 from './Ethereum_BASTARDGANPUNKSV2';
import * as Ethereum_BoredApeYachtClub from './Ethereum_BoredApeYachtClub';
import * as Polygon_TheRedVillageChampions from './Polygon_TheRedVillageChampions';
import * as Ethereum_BossBeauties from './Ethereum_BossBeauties';
import * as Ethereum_ChainRunners from './Ethereum_ChainRunners';
import * as Polygon_ZEDHorse from './Polygon_ZEDHorse';
import * as Ethereum_CoolCatsNFT from './Ethereum_CoolCatsNFT';
import * as Ethereum_Corruption from './Ethereum_Corruption';
import * as Polygon_ZEDRUN from './Polygon_ZEDRUN';
import * as BSC_IARNFTs from './BSC_IARNFTs';
import * as Ethereum_CosmicLabs from './Ethereum_CosmicLabs';
import * as Polygon_BillionaireZombiesClub from './Polygon_BillionaireZombiesClub';
import * as Fantom_BillionaireApe from './Fantom_BillionaireApe';
import * as BSC_Kryptomon from './BSC_Kryptomon';
import * as Ethereum_CrypToadz from './Ethereum_CrypToadz';
import * as Polygon_ChickenDerby from './Polygon_ChickenDerby';
import * as BSC_LittleGhosts from './BSC_LittleGhosts';
import * as Ethereum_CryptoPunks from './Ethereum_CryptoPunks';
import * as Fantom_CryptoWeeds from './Fantom_CryptoWeeds';
import * as BSC_LLAMABILLS from './BSC_LLAMABILLS';
import * as Polygon_comethspaceships from './Polygon_comethspaceships';
import * as BSC_LootDragon from './BSC_LootDragon';
import * as Ethereum_Decentraland from './Ethereum_Decentraland';
import * as Fantom_FantomKittens from './Fantom_FantomKittens';
import * as BSC_MASTERCHEF2NFTS from './BSC_MASTERCHEF2NFTS';
import * as Polygon_CryptoPropheciesItem from './Polygon_CryptoPropheciesItem';
import * as BSC_MinesofDalarniaCanaryBirdsCollection from './BSC_MinesofDalarniaCanaryBirdsCollection';
import * as Ethereum_Degenz from './Ethereum_Degenz';
import * as Fantom_FANTOPEPE from './Fantom_FANTOPEPE';
import * as BSC_Moonpoly from './BSC_Moonpoly';
import * as Polygon_CryptoPropheciesProphet from './Polygon_CryptoPropheciesProphet';
import * as BSC_MythNFT from './BSC_MythNFT';
import * as Ethereum_DeRaceTicket from './Ethereum_DeRaceTicket';
import * as Fantom_FTMCyberPunk from './Fantom_FTMCyberPunk';
import * as BSC_NonFungibleApes from './BSC_NonFungibleApes';
import * as Polygon_CryptoUnicornsMarket from './Polygon_CryptoUnicornsMarket';
import * as BSC_NonFungibleTakos from './BSC_NonFungibleTakos';
import * as Ethereum_DINOX from './Ethereum_DINOX';
import * as Fantom_SpookySkeletoons from './Fantom_SpookySkeletoons';
import * as Ethereum_TheSandbox from './Ethereum_TheSandbox';
import * as Ethereum_TopDogBeachClub from './Ethereum_TopDogBeachClub';
const collectors = new Map();
collectors.set('Polygon_CryptoChillouts', Polygon_CryptoChillouts.default);
collectors.set('Ethereum_DivineAnarchy', Ethereum_DivineAnarchy.default);
collectors.set('BSC_AlpiesBSC', BSC_AlpiesBSC.default);
collectors.set('BSC_ArtexNFT', BSC_ArtexNFT.default);
collectors.set('BSC_BabyBadasses', BSC_BabyBadasses.default);
collectors.set('Polygon_Cryptofarmeur', Polygon_Cryptofarmeur.default);
collectors.set('Ethereum_DogsofElon', Ethereum_DogsofElon.default);
collectors.set('BSC_BinanceBullSoc', BSC_BinanceBullSoc.default);
collectors.set('BSC_BINOhouse', BSC_BINOhouse.default);
collectors.set('BSC_BlocksportNFT', BSC_BlocksportNFT.default);
collectors.set('Polygon_DecentralGamesICEPoker', Polygon_DecentralGamesICEPoker.default);
collectors.set('Ethereum_Doodles', Ethereum_Doodles.default);
collectors.set('BSC_BlocksportNFTTickets', BSC_BlocksportNFTTickets.default);
collectors.set('BSC_BNBPunks', BSC_BNBPunks.default);
collectors.set('BSC_BornBadBoys', BSC_BornBadBoys.default);
collectors.set('Polygon_DeRaceHorses', Polygon_DeRaceHorses.default);
collectors.set('Ethereum_JayPegsAutoMart', Ethereum_JayPegsAutoMart.default);
collectors.set('BSC_BornBadGirls', BSC_BornBadGirls.default);
collectors.set('BSC_BSCLoot', BSC_BSCLoot.default);
collectors.set('BSC_CactiClub', BSC_CactiClub.default);
collectors.set('Polygon_DeRaceJockeyClub', Polygon_DeRaceJockeyClub.default);
collectors.set('Ethereum_LoneleyAliensSpaceClub', Ethereum_LoneleyAliensSpaceClub.default);
collectors.set('BSC_CartelPunks', BSC_CartelPunks.default);
collectors.set('BSC_DeRaceTicket', BSC_DeRaceTicket.default);
collectors.set('BSC_DogePunks', BSC_DogePunks.default);
collectors.set('Polygon_EETHs', Polygon_EETHs.default);
collectors.set('Ethereum_MutantApeYachtClub', Ethereum_MutantApeYachtClub.default);
collectors.set('BSC_EIGHTBITFUN', BSC_EIGHTBITFUN.default);
collectors.set('BSC_FrostyGenesis', BSC_FrostyGenesis.default);
collectors.set('BSC_FungieColors', BSC_FungieColors.default);
collectors.set('Polygon_EmberSwordBadge', Polygon_EmberSwordBadge.default);
collectors.set('Ethereum_NFTradeBreakpointParty', Ethereum_NFTradeBreakpointParty.default);
collectors.set('BSC_GamerNFLs', BSC_GamerNFLs.default);
collectors.set('BSC_GangsterPunks', BSC_GangsterPunks.default);
collectors.set('BSC_HatSwap', BSC_HatSwap.default);
collectors.set('Polygon_EmberSwordLand', Polygon_EmberSwordLand.default);
collectors.set('Ethereum_PartyApes', Ethereum_PartyApes.default);
collectors.set('BSC_Opulous', BSC_Opulous.default);
collectors.set('BSC_PipemoonCapsule', BSC_PipemoonCapsule.default);
collectors.set('BSC_PokelonEggs', BSC_PokelonEggs.default);
collectors.set('Polygon_EvolutionLandObjects', Polygon_EvolutionLandObjects.default);
collectors.set('Ethereum_PolychainMonsters', Ethereum_PolychainMonsters.default);
collectors.set('BSC_PolychainMonsters', BSC_PolychainMonsters.default);
collectors.set('BSC_Poopies', BSC_Poopies.default);
collectors.set('BSC_PrelaxNFT', BSC_PrelaxNFT.default);
collectors.set('Polygon_Fear', Polygon_Fear.default);
collectors.set('Ethereum_ProofofSteak', Ethereum_ProofofSteak.default);
collectors.set('BSC_REALM', BSC_REALM.default);
collectors.set('BSC_StarNFTProxyAlpacaFinance', BSC_StarNFTProxyAlpacaFinance.default);
collectors.set('BSC_StarryNift', BSC_StarryNift.default);
collectors.set('Polygon_LeagueofKingdomsITEM', Polygon_LeagueofKingdomsITEM.default);
collectors.set('Ethereum_SadFrogsDistrict', Ethereum_SadFrogsDistrict.default);
collectors.set('BSC_TheDJTCollection', BSC_TheDJTCollection.default);
collectors.set('BSC_TheFOMOChronicles', BSC_TheFOMOChronicles.default);
collectors.set('BSC_TokAuStarryToken', BSC_TokAuStarryToken.default);
collectors.set('Polygon_MoonieNFTOfficialCollection', Polygon_MoonieNFTOfficialCollection.default);
collectors.set('Ethereum_SipherianFlash', Ethereum_SipherianFlash.default);
collectors.set('BSC_TTKHero', BSC_TTKHero.default);
collectors.set('BSC_TurtlePunks', BSC_TurtlePunks.default);
collectors.set('BSC_Warship', BSC_Warship.default);
collectors.set('Polygon_NeonDistrictSeasonOneItem', Polygon_NeonDistrictSeasonOneItem.default);
collectors.set('Ethereum_StarlinkPixelNauts', Ethereum_StarlinkPixelNauts.default);
collectors.set('BSC_WeAreSatoshi', BSC_WeAreSatoshi.default);
collectors.set('BSC_Weapon', BSC_Weapon.default);
collectors.set('BSC_XCGenesis_N', BSC_XCGenesis_N.default);
collectors.set('Ethereum_XCADNFT', Ethereum_XCADNFT.default);
collectors.set('BSC_XCGenesis_R', BSC_XCGenesis_R.default);
collectors.set('BSC_XCGenesis_S', BSC_XCGenesis_S.default);
collectors.set('BSC_XCGenesis_SR', BSC_XCGenesis_SR.default);
collectors.set('Polygon_PolygonPunks', Polygon_PolygonPunks.default);
collectors.set('Ethereum_THEREALGOATSOCIETY', Ethereum_THEREALGOATSOCIETY.default);
collectors.set('BSC_XCGenesis_SSR', BSC_XCGenesis_SSR.default);
collectors.set('BSC_YieldProtocol', BSC_YieldProtocol.default);
collectors.set('BSC_ZecreyChameleonAvatar', BSC_ZecreyChameleonAvatar.default);
collectors.set('Polygon_rabbitholegg', Polygon_rabbitholegg.default);
collectors.set('Ethereum_Alpies', Ethereum_Alpies.default);
collectors.set('Ethereum_ArtBlocks', Ethereum_ArtBlocks.default);
collectors.set('Polygon_REVVRacing', Polygon_REVVRacing.default);
collectors.set('Ethereum_BASTARDGANPUNKSV2', Ethereum_BASTARDGANPUNKSV2.default);
collectors.set('Ethereum_BoredApeYachtClub', Ethereum_BoredApeYachtClub.default);
collectors.set('Polygon_TheRedVillageChampions', Polygon_TheRedVillageChampions.default);
collectors.set('Ethereum_BossBeauties', Ethereum_BossBeauties.default);
collectors.set('Ethereum_ChainRunners', Ethereum_ChainRunners.default);
collectors.set('Polygon_ZEDHorse', Polygon_ZEDHorse.default);
collectors.set('Ethereum_CoolCatsNFT', Ethereum_CoolCatsNFT.default);
collectors.set('Ethereum_Corruption', Ethereum_Corruption.default);
collectors.set('Polygon_ZEDRUN', Polygon_ZEDRUN.default);
collectors.set('BSC_IARNFTs', BSC_IARNFTs.default);
collectors.set('Ethereum_CosmicLabs', Ethereum_CosmicLabs.default);
collectors.set('Polygon_BillionaireZombiesClub', Polygon_BillionaireZombiesClub.default);
collectors.set('Fantom_BillionaireApe', Fantom_BillionaireApe.default);
collectors.set('BSC_Kryptomon', BSC_Kryptomon.default);
collectors.set('Ethereum_CrypToadz', Ethereum_CrypToadz.default);
collectors.set('Polygon_ChickenDerby', Polygon_ChickenDerby.default);
collectors.set('BSC_LittleGhosts', BSC_LittleGhosts.default);
collectors.set('Ethereum_CryptoPunks', Ethereum_CryptoPunks.default);
collectors.set('Fantom_CryptoWeeds', Fantom_CryptoWeeds.default);
collectors.set('BSC_LLAMABILLS', BSC_LLAMABILLS.default);
collectors.set('Polygon_comethspaceships', Polygon_comethspaceships.default);
collectors.set('BSC_LootDragon', BSC_LootDragon.default);
collectors.set('Ethereum_Decentraland', Ethereum_Decentraland.default);
collectors.set('Fantom_FantomKittens', Fantom_FantomKittens.default);
collectors.set('BSC_MASTERCHEF2NFTS', BSC_MASTERCHEF2NFTS.default);
collectors.set('Polygon_CryptoPropheciesItem', Polygon_CryptoPropheciesItem.default);
collectors.set('BSC_MinesofDalarniaCanaryBirdsCollection', BSC_MinesofDalarniaCanaryBirdsCollection.default);
collectors.set('Ethereum_Degenz', Ethereum_Degenz.default);
collectors.set('Fantom_FANTOPEPE', Fantom_FANTOPEPE.default);
collectors.set('BSC_Moonpoly', BSC_Moonpoly.default);
collectors.set('Polygon_CryptoPropheciesProphet', Polygon_CryptoPropheciesProphet.default);
collectors.set('BSC_MythNFT', BSC_MythNFT.default);
collectors.set('Ethereum_DeRaceTicket', Ethereum_DeRaceTicket.default);
collectors.set('Fantom_FTMCyberPunk', Fantom_FTMCyberPunk.default);
collectors.set('BSC_NonFungibleApes', BSC_NonFungibleApes.default);
collectors.set('Polygon_CryptoUnicornsMarket', Polygon_CryptoUnicornsMarket.default);
collectors.set('BSC_NonFungibleTakos', BSC_NonFungibleTakos.default);
collectors.set('Ethereum_DINOX', Ethereum_DINOX.default);
collectors.set('Fantom_SpookySkeletoons', Fantom_SpookySkeletoons.default);
collectors.set('Ethereum_TheSandbox', Ethereum_TheSandbox.default);
collectors.set('Ethereum_TopDogBeachClub', Ethereum_TopDogBeachClub.default);
export default collectors;