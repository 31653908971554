import React, { useState, useEffect } from "react";
import ChatContractHelper from "../ChatContractHelper"
import { getCurrentNetwork } from "../Blockchains"
import { log } from "../debugUtils"

function WelcomeMsg() {
  const [subsFee, setSubsFee] = useState(null);
  let welcomeMsg;

  useEffect(() => {
    if (getCurrentNetwork()) {
      getSubscriptionFees()
    }
  });

  const getSubscriptionFees = async () => {
    const contractHelper = new ChatContractHelper()
    await contractHelper.loadWeb3(()=>{})
    const subsFee = await contractHelper.getSubscriptionFeeInNativeToken()
    log(`Setting subscriptionFee: ${subsFee} `)
    setSubsFee(subsFee)
  }

  if (getCurrentNetwork()) {
    const token = getCurrentNetwork().token
    welcomeMsg = <div id="welcomeMsg">
      Welcome to NFTchat, <br></br><br></br>
      It seems like this is the first time you visit our place. <br></br>
      Once you transfer the subscription fee of {subsFee} {token} you will be able to send ♾️ NFT messages.  <br></br>
      <br></br>
      If you feel ready to join us in the metaverse just hit the Subscribe button 👇
    </div>
  } else {
    welcomeMsg = <div id="welcomeMsg">
      Welcome to NFTchat, <br></br><br></br>
      We haven't detected a Web3 compatible browser.<br></br>
      Consider using a wallet browser or an extension like Metamask.<br></br>
      <br></br>
      Then, you'll be able to join us in the metaverse 👾
    </div>
  }

  return <div id="welcomeContainer">
    {welcomeMsg}
  </div>;
}

export default WelcomeMsg;

