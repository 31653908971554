const collections = [];

function initCollections() {
    collections.push({ 'blockchain': 'Polygon', 'name': 'CryptoChillouts', 'address': '0xa200a54daed579fda6f5ed86de93047bd9d595d7', 'filename': 'Polygon_CryptoChillouts', 'holders': 24 })
        //Divine Anarchy List length 10000 >= 10000 I'm going to fetch them also in Descending Order: isAsc=false
    collections.push({ 'blockchain': 'Ethereum', 'name': 'Divine Anarchy', 'address': '0xc631164b6cb1340b5123c9162f8558c866de1926', 'filename': 'Ethereum_DivineAnarchy', 'holders': 8049 })
    collections.push({ 'blockchain': 'BSC', 'name': 'AlpiesBSC', 'address': '0x57a7c5d10c3f87f5617ac1c60da60082e44d539e', 'filename': 'BSC_AlpiesBSC', 'holders': 1567 })
    collections.push({ 'blockchain': 'BSC', 'name': 'Artex NFT', 'address': '0xb9174a1d7cd36478957c269e889f4967b89274e2', 'filename': 'BSC_ArtexNFT', 'holders': 22 })
    collections.push({ 'blockchain': 'BSC', 'name': 'BabyBadasses', 'address': '0xbfed4765d13ce2e300c09d2d61d2b0163993a99c', 'filename': 'BSC_BabyBadasses', 'holders': 532 })
    collections.push({ 'blockchain': 'Polygon', 'name': 'Cryptofarmeur', 'address': '0xaa8e4e0062fe37b96f5c486f6245a1e9c4b46391', 'filename': 'Polygon_Cryptofarmeur', 'holders': 9 })
    collections.push({ 'blockchain': 'Ethereum', 'name': 'Dogs of Elon', 'address': '0xd8cdb4b17a741dc7c6a57a650974cd2eba544ff7', 'filename': 'Ethereum_DogsofElon', 'holders': 2809 })
    collections.push({ 'blockchain': 'BSC', 'name': 'BinanceBullSoc', 'address': '0xa46a4920b40f134420b472b16b3328d74d7b6b70', 'filename': 'BSC_BinanceBullSoc', 'holders': 1271 })
    collections.push({ 'blockchain': 'BSC', 'name': 'BINO house', 'address': '0x3158b2432d19287e0166bf87698bfefe89460573', 'filename': 'BSC_BINOhouse', 'holders': 3459 })
    collections.push({ 'blockchain': 'BSC', 'name': 'BlocksportNFT', 'address': '0x62fbbe6a63ea92d1be3c33b520eddcf857952b9b', 'filename': 'BSC_BlocksportNFT', 'holders': 1007 })
    collections.push({ 'blockchain': 'Polygon', 'name': 'Decentral Games ICE Poker', 'address': '0xD07A56F7198ae6e4e3D6738Bd8c4b81d21bf0403', 'filename': 'Polygon_DecentralGamesICEPoker', 'holders': 22 })
        //Doodles List length 10000 >= 10000 I'm going to fetch them also in Descending Order: isAsc=false
    collections.push({ 'blockchain': 'Ethereum', 'name': 'Doodles', 'address': '0x8a90cab2b38dba80c64b7734e58ee1db38b8992e', 'filename': 'Ethereum_Doodles', 'holders': 12459 })
        //BlocksportNFTTickets List length 10000 >= 10000 I'm going to fetch them also in Descending Order: isAsc=false
    collections.push({ 'blockchain': 'BSC', 'name': 'BlocksportNFTTickets', 'address': '0x972d2647d8f564f1835bc585447da8e81ec35735', 'filename': 'BSC_BlocksportNFTTickets', 'holders': 7941 })
    collections.push({ 'blockchain': 'BSC', 'name': 'BNBPunks', 'address': '0xda938c7b9cb9f357c7f51d6aaa6b4fd9a0d6fd92', 'filename': 'BSC_BNBPunks', 'holders': 568 })
        //BornBadBoys List length 10000 >= 10000 I'm going to fetch them also in Descending Order: isAsc=false
    collections.push({ 'blockchain': 'BSC', 'name': 'BornBadBoys', 'address': '0x44d85770aea263f9463418708125cd95e308299b', 'filename': 'BSC_BornBadBoys', 'holders': 4096 })
    collections.push({ 'blockchain': 'Polygon', 'name': 'DeRace Horses', 'address': '0x9d77cb4D8371736e2A2b2bfAa677b7841cDc8fC1', 'filename': 'Polygon_DeRaceHorses', 'holders': 1055 })
    collections.push({ 'blockchain': 'Ethereum', 'name': 'Jay Pegs Auto Mart', 'address': '0xf210d5d9dcf958803c286a6f8e278e4ac78e136e', 'filename': 'Ethereum_JayPegsAutoMart', 'holders': 1401 })
    collections.push({ 'blockchain': 'BSC', 'name': 'BornBadGirls', 'address': '0x3da8410e6ef658c06e277a2769816688c37496cf', 'filename': 'BSC_BornBadGirls', 'holders': 1488 })
    collections.push({ 'blockchain': 'BSC', 'name': 'BSC Loot', 'address': '0x58a6e52754c9fca3975883d212c9fb04107e242b', 'filename': 'BSC_BSCLoot', 'holders': 143 })
        //Cacti Club List length 10000 >= 10000 I'm going to fetch them also in Descending Order: isAsc=false
    collections.push({ 'blockchain': 'BSC', 'name': 'Cacti Club', 'address': '0x9432e72f68614ffdd3ba82616ac59e878284aac4', 'filename': 'BSC_CactiClub', 'holders': 1237 })
    collections.push({ 'blockchain': 'Polygon', 'name': 'DeRace Jockey Club', 'address': '0x5F6ceAAD29038435A764B59D716717500135a609', 'filename': 'Polygon_DeRaceJockeyClub', 'holders': 45 })
        //Loneley Aliens Space Club List length 10000 >= 10000 I'm going to fetch them also in Descending Order: isAsc=false
    collections.push({ 'blockchain': 'Ethereum', 'name': 'Loneley Aliens Space Club', 'address': '0x343f999eaacdfa1f201fb8e43ebb35c99d9ae0c1', 'filename': 'Ethereum_LoneleyAliensSpaceClub', 'holders': 5226 })
    collections.push({ 'blockchain': 'BSC', 'name': 'CartelPunks', 'address': '0x4c1900270dbf0c1e6a9c984aef9a18a7cb9ab1cc', 'filename': 'BSC_CartelPunks', 'holders': 665 })
    collections.push({ 'blockchain': 'BSC', 'name': 'DeRace Ticket', 'address': '0x463cfd5b84374dda419c6aed769c67790060fba9', 'filename': 'BSC_DeRaceTicket', 'holders': 249 })
    collections.push({ 'blockchain': 'BSC', 'name': 'DogePunks', 'address': '0xc00074337f9d24ec45a2879f6788e1524f481a43', 'filename': 'BSC_DogePunks', 'holders': 455 })
    collections.push({ 'blockchain': 'Polygon', 'name': 'EETHs', 'address': '0xfa781ed91fba381f73c477327641242ce55fab5b', 'filename': 'Polygon_EETHs', 'holders': 83 })
        //Mutant Ape Yacht Club List length 10000 >= 10000 I'm going to fetch them also in Descending Order: isAsc=false
    collections.push({ 'blockchain': 'Ethereum', 'name': 'Mutant Ape Yacht Club', 'address': '0x60e4d786628fea6478f785a6d7e704777c86a7c6', 'filename': 'Ethereum_MutantApeYachtClub', 'holders': 11500 })
    collections.push({ 'blockchain': 'BSC', 'name': 'EIGHTBITFUN', 'address': '0xc2027db8c0d68bfd60ff394e47dc210fc9e1407a', 'filename': 'BSC_EIGHTBITFUN', 'holders': 118 })
    collections.push({ 'blockchain': 'BSC', 'name': 'FrostyGenesis', 'address': '0x2684b1d6b260648fef13f1a8b55d311a4bdf32f2', 'filename': 'BSC_FrostyGenesis', 'holders': 82 })
    collections.push({ 'blockchain': 'BSC', 'name': 'Fungie Colors', 'address': '0xd2d6554f218dc7e10193a55697b768766ff2ba5f', 'filename': 'BSC_FungieColors', 'holders': 16 })
    collections.push({ 'blockchain': 'Polygon', 'name': 'Ember Sword Badge', 'address': '0xa5511E9941E303101b50675926Fd4d9c1A8a8805', 'filename': 'Polygon_EmberSwordBadge', 'holders': 2488 })
    collections.push({ 'blockchain': 'Ethereum', 'name': 'NFTrade Breakpoint Party', 'address': '0x7339453b0c6947f33f95278a2589dab317612254', 'filename': 'Ethereum_NFTradeBreakpointParty', 'holders': 31 })
        //Gamer NFLs List length 10000 >= 10000 I'm going to fetch them also in Descending Order: isAsc=false
    collections.push({ 'blockchain': 'BSC', 'name': 'Gamer NFLs', 'address': '0xe9b9497798b5fe949039c01b1a772bdcb7e9ba10', 'filename': 'BSC_GamerNFLs', 'holders': 2985 })
    collections.push({ 'blockchain': 'BSC', 'name': 'Gangster Punks', 'address': '0x897fd4ce6dbcbf31039754fdc8f77ce4c3521ee0', 'filename': 'BSC_GangsterPunks', 'holders': 633 })
    collections.push({ 'blockchain': 'BSC', 'name': 'Hat Swap', 'address': '0x1e8dc7d18a655e7386eb0c957b61c6a487803a88', 'filename': 'BSC_HatSwap', 'holders': 924 })
        //Ember Sword Land List length 10000 >= 10000 I'm going to fetch them also in Descending Order: isAsc=false
    collections.push({ 'blockchain': 'Polygon', 'name': 'Ember Sword Land', 'address': '0xE7e16f2Da731265778f87cB8D7850E31b84b7b86', 'filename': 'Polygon_EmberSwordLand', 'holders': 1308 })
    collections.push({ 'blockchain': 'Ethereum', 'name': 'PartyApes', 'address': '0xedeaa6f6b1d8bccf84b1ec60f758606413c3c481', 'filename': 'Ethereum_PartyApes', 'holders': 148 })
    collections.push({ 'blockchain': 'BSC', 'name': 'Opulous', 'address': '0x35781823c1cb18853039dac569b5f6dae8b57ebf', 'filename': 'BSC_Opulous', 'holders': 74 })
    collections.push({ 'blockchain': 'BSC', 'name': 'Pipemoon Capsule', 'address': '0x7acaf182be62de7279b80b1bdaf5a666e16dd8ea', 'filename': 'BSC_PipemoonCapsule', 'holders': 71 })
    collections.push({ 'blockchain': 'BSC', 'name': 'Pokelon Eggs', 'address': '0x5376f2e536d9da963b11da6c845086a0026c75d6', 'filename': 'BSC_PokelonEggs', 'holders': 5 })
        //Evolution Land Objects List length 10000 >= 10000 I'm going to fetch them also in Descending Order: isAsc=false
    collections.push({ 'blockchain': 'Polygon', 'name': 'Evolution Land Objects', 'address': '0x41f4845d0ed269f6205d4542a5165255a9d6e8cf', 'filename': 'Polygon_EvolutionLandObjects', 'holders': 1593 })
        //Polychain Monsters List length 10000 >= 10000 I'm going to fetch them also in Descending Order: isAsc=false
    collections.push({ 'blockchain': 'Ethereum', 'name': 'Polychain Monsters', 'address': '0x85f0e02cb992aa1f9f47112f815f519ef1a59e2d', 'filename': 'Ethereum_PolychainMonsters', 'holders': 6398 })
        //Polychain Monsters List length 10000 >= 10000 I'm going to fetch them also in Descending Order: isAsc=false
    collections.push({ 'blockchain': 'BSC', 'name': 'Polychain Monsters', 'address': '0x85f0e02cb992aa1f9f47112f815f519ef1a59e2d', 'filename': 'BSC_PolychainMonsters', 'holders': 7680 })
    collections.push({ 'blockchain': 'BSC', 'name': 'Poopies', 'address': '0xda6d7d97f3b4dd69949e48752019bebeeb2a0ca6', 'filename': 'BSC_Poopies', 'holders': 2220 })
    collections.push({ 'blockchain': 'BSC', 'name': 'PrelaxNFT', 'address': '0x35f4512bfe08c0daea41fcbe43b65106f91d953b', 'filename': 'BSC_PrelaxNFT', 'holders': 4277 })
        //Fear List length 10000 >= 10000 I'm going to fetch them also in Descending Order: isAsc=false
    collections.push({ 'blockchain': 'Polygon', 'name': 'Fear', 'address': '0xb9aee1ad85bed213b53329f060a13328eb26b7a0', 'filename': 'Polygon_Fear', 'holders': 3927 })
    collections.push({ 'blockchain': 'Ethereum', 'name': 'Proof of Steak', 'address': '0xcab65c60d9dc47e1d450c7e9074f73f1ff75f181', 'filename': 'Ethereum_ProofofSteak', 'holders': 1421 })
    collections.push({ 'blockchain': 'BSC', 'name': 'REALM', 'address': '0x7a6c0b032ee2d0aea94e5d1c045cd174d8109337', 'filename': 'BSC_REALM', 'holders': 151 })
        //StarNFT Proxy Alpaca Finance List length 10000 >= 10000 I'm going to fetch them also in Descending Order: isAsc=false
    collections.push({ 'blockchain': 'BSC', 'name': 'StarNFT Proxy Alpaca Finance', 'address': '0xe85d7b8f4c0c13806e158a1c9d7dcb33140cdc46', 'filename': 'BSC_StarNFTProxyAlpacaFinance', 'holders': 4751 })
        //StarryNift List length 10000 >= 10000 I'm going to fetch them also in Descending Order: isAsc=false
    collections.push({ 'blockchain': 'BSC', 'name': 'StarryNift', 'address': '0xd9de8f63ea0f18264fa5c17a8f17e8ea06367649', 'filename': 'BSC_StarryNift', 'holders': 3837 })
    collections.push({ 'blockchain': 'Polygon', 'name': 'League of Kingdoms ITEM', 'address': '0x4d544035500d7ac1b42329c70eb58e77f8249f0f', 'filename': 'Polygon_LeagueofKingdomsITEM', 'holders': 3106 })
    collections.push({ 'blockchain': 'Ethereum', 'name': 'Sad Frogs District', 'address': '0x772c9181b0596229ce5ba898772ce45188284379', 'filename': 'Ethereum_SadFrogsDistrict', 'holders': 5203 })
    collections.push({ 'blockchain': 'BSC', 'name': 'The DJT Collection', 'address': '0x1c89f9e644aaa08dfb762bce95061ecfc74c37df', 'filename': 'BSC_TheDJTCollection', 'holders': 211 })
    collections.push({ 'blockchain': 'BSC', 'name': 'The FOMO Chronicles', 'address': '0x61d9f45058751d5988862bdba2669f909972daf4', 'filename': 'BSC_TheFOMOChronicles', 'holders': 503 })
    collections.push({ 'blockchain': 'BSC', 'name': 'TokAu Starry Token', 'address': '0x4e64198c1e13248bfc0e63d53e03460dbb383a94', 'filename': 'BSC_TokAuStarryToken', 'holders': 160 })
    collections.push({ 'blockchain': 'Polygon', 'name': 'MoonieNFT Official Collection', 'address': '0xcab4f7f57af24cef0a46eed4150a23b36c29d6cc', 'filename': 'Polygon_MoonieNFTOfficialCollection', 'holders': 169 })
    collections.push({ 'blockchain': 'Ethereum', 'name': 'Sipherian Flash', 'address': '0x09e0df4ae51111ca27d6b85708cfb3f1f7cae982', 'filename': 'Ethereum_SipherianFlash', 'holders': 2082 })
    collections.push({ 'blockchain': 'BSC', 'name': 'TTK Hero', 'address': '0xc0bfe67cedc9fe042f335258eb75b9e1baaf1a5d', 'filename': 'BSC_TTKHero', 'holders': 1420 })
    collections.push({ 'blockchain': 'BSC', 'name': 'TurtlePunks', 'address': '0xe6bcdb18e8c77724cade686f242f3dc408a55bae', 'filename': 'BSC_TurtlePunks', 'holders': 303 })
    collections.push({ 'blockchain': 'BSC', 'name': 'Warship', 'address': '0x3ca1299c029b78fd9809b11669b69dbbf3f773da', 'filename': 'BSC_Warship', 'holders': 816 })
        //Neon District Season One Item List length 10000 >= 10000 I'm going to fetch them also in Descending Order: isAsc=false
    collections.push({ 'blockchain': 'Polygon', 'name': 'Neon District Season One Item', 'address': '0x7227e371540CF7b8e512544Ba6871472031F3335', 'filename': 'Polygon_NeonDistrictSeasonOneItem', 'holders': 878 })
    collections.push({ 'blockchain': 'Ethereum', 'name': 'Starlink PixelNauts', 'address': '0x27cc171b16fdd2224e1f93ed7dccfcc6790ca4bf', 'filename': 'Ethereum_StarlinkPixelNauts', 'holders': 1439 })
    collections.push({ 'blockchain': 'BSC', 'name': 'We Are Satoshi', 'address': '0xe027a1b8140b8af8a13765a31c5bb952bf5ea8b2', 'filename': 'BSC_WeAreSatoshi', 'holders': 176 })
        //Weapon List length 10000 >= 10000 I'm going to fetch them also in Descending Order: isAsc=false
    collections.push({ 'blockchain': 'BSC', 'name': 'Weapon', 'address': '0x6db417a6158b5fc7ee5fd5b469d54a606ff180f6', 'filename': 'BSC_Weapon', 'holders': 535 })
        //XCGenesis_N List length 10000 >= 10000 I'm going to fetch them also in Descending Order: isAsc=false
    collections.push({ 'blockchain': 'BSC', 'name': 'XCGenesis_N', 'address': '0x087e196cec81c1ffd86f0d534b7a8be9d766c2dd', 'filename': 'BSC_XCGenesis_N', 'holders': 2987 })
    collections.push({ 'blockchain': 'Ethereum', 'name': 'XCAD NFT', 'address': '0x22ca5e08a37979802e58c113e770f56ba352b1ed', 'filename': 'Ethereum_XCADNFT', 'holders': 86 })
    collections.push({ 'blockchain': 'BSC', 'name': 'XCGenesis_R', 'address': '0x33c50d92918f5ffac12345b4c93e76c26223c12d', 'filename': 'BSC_XCGenesis_R', 'holders': 1448 })
    collections.push({ 'blockchain': 'BSC', 'name': 'XCGenesis_S', 'address': '0xf0729779673fd2f1d126958bc5c929717af0efe5', 'filename': 'BSC_XCGenesis_S', 'holders': 1021 })
    collections.push({ 'blockchain': 'BSC', 'name': 'XCGenesis_SR', 'address': '0xc9f6138fb46abe4742115270533eeeab26d315af', 'filename': 'BSC_XCGenesis_SR', 'holders': 395 })
        //PolygonPunks List length 10000 >= 10000 I'm going to fetch them also in Descending Order: isAsc=false
    collections.push({ 'blockchain': 'Polygon', 'name': 'PolygonPunks', 'address': '0x9498274b8c82b4a3127d67839f2127f2ae9753f4', 'filename': 'Polygon_PolygonPunks', 'holders': 1797 })
    collections.push({ 'blockchain': 'Ethereum', 'name': 'THE REAL GOAT SOCIETY', 'address': '0x2f14f1b6c350c41801b2b7ba9445670d7e2ffc70', 'filename': 'Ethereum_THEREALGOATSOCIETY', 'holders': 4084 })
    collections.push({ 'blockchain': 'BSC', 'name': 'XCGenesis_SSR', 'address': '0x1740dac1f741e557e62b2b4fd8a70067696bae09', 'filename': 'BSC_XCGenesis_SSR', 'holders': 99 })
    collections.push({ 'blockchain': 'BSC', 'name': 'Yield Protocol', 'address': '0xe8b34f36bc21872132f69943904999392e169a0a', 'filename': 'BSC_YieldProtocol', 'holders': 15 })
    collections.push({ 'blockchain': 'BSC', 'name': 'Zecrey Chameleon Avatar', 'address': '0x8c6be54dcca5eed3390b37c9d8ef9eb0358ac3a8', 'filename': 'BSC_ZecreyChameleonAvatar', 'holders': 581 })
        //rabbitholegg List length 10000 >= 10000 I'm going to fetch them also in Descending Order: isAsc=false
    collections.push({ 'blockchain': 'Polygon', 'name': 'rabbitholegg', 'address': '0xF9481510210C13Be887d200fBb03036088638D79', 'filename': 'Polygon_rabbitholegg', 'holders': 1472 })
    collections.push({ 'blockchain': 'Ethereum', 'name': 'Alpies', 'address': '0x57a7c5d10c3f87f5617ac1c60da60082e44d539e', 'filename': 'Ethereum_Alpies', 'holders': 1065 })
        //Art Blocks List length 10000 >= 10000 I'm going to fetch them also in Descending Order: isAsc=false
    collections.push({ 'blockchain': 'Ethereum', 'name': 'Art Blocks', 'address': '0x059edd72cd353df5106d2b9cc5ab83a52287ac3a', 'filename': 'Ethereum_ArtBlocks', 'holders': 3290 })
        //REVV Racing List length 10000 >= 10000 I'm going to fetch them also in Descending Order: isAsc=false
    collections.push({ 'blockchain': 'Polygon', 'name': 'REVV Racing', 'address': '0x51Ac4A13054D5d7e1FA795439821484177e7E828', 'filename': 'Polygon_REVVRacing', 'holders': 11601 })
        //BASTARD GAN PUNKS V2 List length 10000 >= 10000 I'm going to fetch them also in Descending Order: isAsc=false
    collections.push({ 'blockchain': 'Ethereum', 'name': 'BASTARD GAN PUNKS V2', 'address': '0x31385d3520bced94f77aae104b406994d8f2168c', 'filename': 'Ethereum_BASTARDGANPUNKSV2', 'holders': 4207 })
        //Bored Ape Yacht Club List length 10000 >= 10000 I'm going to fetch them also in Descending Order: isAsc=false
    collections.push({ 'blockchain': 'Ethereum', 'name': 'Bored Ape Yacht Club', 'address': '0xBC4CA0EdA7647A8aB7C2061c2E118A18a936f13D', 'filename': 'Ethereum_BoredApeYachtClub', 'holders': 9588 })
    collections.push({ 'blockchain': 'Polygon', 'name': 'The Red Village Champions', 'address': '0x4055e3503D1221Af4b187CF3B4aa8744332A4d0b', 'filename': 'Polygon_TheRedVillageChampions', 'holders': 633 })
    collections.push({ 'blockchain': 'Ethereum', 'name': 'Boss Beauties', 'address': '0xb5c747561a185a146f83cfff25bdfd2455b31ff4', 'filename': 'Ethereum_BossBeauties', 'holders': 5304 })
    collections.push({ 'blockchain': 'Ethereum', 'name': 'Chain Runners', 'address': '0x97597002980134bea46250aa0510c9b90d87a587', 'filename': 'Ethereum_ChainRunners', 'holders': 3694 })
        //ZED Horse List length 10000 >= 10000 I'm going to fetch them also in Descending Order: isAsc=false
    collections.push({ 'blockchain': 'Polygon', 'name': 'ZED Horse', 'address': '0xa5f1ea7df861952863df2e8d1312f7305dabf215', 'filename': 'Polygon_ZEDHorse', 'holders': 1003 })
        //Cool Cats NFT List length 10000 >= 10000 I'm going to fetch them also in Descending Order: isAsc=false
    collections.push({ 'blockchain': 'Ethereum', 'name': 'Cool Cats NFT', 'address': '0x1a92f7381b9f03921564a437210bb9396471050c', 'filename': 'Ethereum_CoolCatsNFT', 'holders': 9129 })
    collections.push({ 'blockchain': 'Ethereum', 'name': 'Corruption', 'address': '0x5bdf397bb2912859dbd8011f320a222f79a28d2e', 'filename': 'Ethereum_Corruption', 'holders': 1678 })
        //ZED RUN	 List length 10000 >= 10000 I'm going to fetch them also in Descending Order: isAsc=false
    collections.push({ 'blockchain': 'Polygon', 'name': 'ZED RUN', 'address': '0xA5F1Ea7DF861952863dF2e8d1312f7305dabf215', 'filename': 'Polygon_ZEDRUN', 'holders': 1003 })
    collections.push({ 'blockchain': 'BSC', 'name': 'IAR NFTs', 'address': '0x50526b8131efda2ebe3dd074d291e9507a815fa1', 'filename': 'BSC_IARNFTs', 'holders': 2 })
        //Cosmic Labs List length 10000 >= 10000 I'm going to fetch them also in Descending Order: isAsc=false
    collections.push({ 'blockchain': 'Ethereum', 'name': 'Cosmic Labs', 'address': '0x96316355c44be69414756d6706c61e61aecbd5f3', 'filename': 'Ethereum_CosmicLabs', 'holders': 5897 })
    collections.push({ 'blockchain': 'Polygon', 'name': 'BillionaireZombiesClub', 'address': '0x4bca2C2ecE9402b5D4dd031b49d48166C40B7957', 'filename': 'Polygon_BillionaireZombiesClub', 'holders': 2198 })
        //Billionaire Ape List length 10000 >= 10000 I'm going to fetch them also in Descending Order: isAsc=false
    collections.push({ 'blockchain': 'Fantom', 'name': 'Billionaire Ape', 'address': '0x61af4d29f672e27a097291f72fc571304bc93521', 'filename': 'Fantom_BillionaireApe', 'holders': 8353 })
        //Kryptomon List length 10000 >= 10000 I'm going to fetch them also in Descending Order: isAsc=false
    collections.push({ 'blockchain': 'BSC', 'name': 'Kryptomon', 'address': '0xc33d69a337b796a9f0f7588169cd874c3987bde9', 'filename': 'BSC_Kryptomon', 'holders': 6449 })
        //CrypToadz List length 10000 >= 10000 I'm going to fetch them also in Descending Order: isAsc=false
    collections.push({ 'blockchain': 'Ethereum', 'name': 'CrypToadz', 'address': '0x1cb1a5e65610aeff2551a50f76a87a7d3fb649c6', 'filename': 'Ethereum_CrypToadz', 'holders': 7352 })
        //Chicken Derby List length 10000 >= 10000 I'm going to fetch them also in Descending Order: isAsc=false
    collections.push({ 'blockchain': 'Polygon', 'name': 'Chicken Derby', 'address': '0x8634666bA15AdA4bbC83B9DbF285F73D9e46e4C2', 'filename': 'Polygon_ChickenDerby', 'holders': 3988 })
    collections.push({ 'blockchain': 'BSC', 'name': 'LittleGhosts', 'address': '0x98f606a4cdde68b9f68732d21fb9ba8b5510ee48', 'filename': 'BSC_LittleGhosts', 'holders': 1330 })
        //CryptoPunks List length 10000 >= 10000 I'm going to fetch them also in Descending Order: isAsc=false
    collections.push({ 'blockchain': 'Ethereum', 'name': 'CryptoPunks', 'address': '0xb47e3cd837ddf8e4c57f05d70ab865de6e193bbb', 'filename': 'Ethereum_CryptoPunks', 'holders': 1803 })
    collections.push({ 'blockchain': 'Fantom', 'name': 'CryptoWeeds', 'address': '0x12094ae07817a2630f9f12fbaa4fb81d93adba2c', 'filename': 'Fantom_CryptoWeeds', 'holders': 61 })
    collections.push({ 'blockchain': 'BSC', 'name': 'LLAMABILLS', 'address': '0x75f48cb5f8e5005303562407f6c48169f6502cdb', 'filename': 'BSC_LLAMABILLS', 'holders': 341 })
        //cometh spaceships List length 10000 >= 10000 I'm going to fetch them also in Descending Order: isAsc=false
    collections.push({ 'blockchain': 'Polygon', 'name': 'cometh spaceships', 'address': '0x85bc2e8aaad5dbc347db49ea45d95486279ed918', 'filename': 'Polygon_comethspaceships', 'holders': 1360 })
    collections.push({ 'blockchain': 'BSC', 'name': 'LootDragon', 'address': '0x8611eaa46bc9d5d838cd7b5d2e79abb538ae99a4', 'filename': 'BSC_LootDragon', 'holders': 261 })
        //Decentraland List length 10000 >= 10000 I'm going to fetch them also in Descending Order: isAsc=false
    collections.push({ 'blockchain': 'Ethereum', 'name': 'Decentraland', 'address': '0xf87e31492faf9a91b02ee0deaad50d51d56d5d4d', 'filename': 'Ethereum_Decentraland', 'holders': 4806 })
    collections.push({ 'blockchain': 'Fantom', 'name': 'Fantom Kittens', 'address': '0xfd211f3b016a75bc8d73550ac5adc2f1cae780c0', 'filename': 'Fantom_FantomKittens', 'holders': 158 })
    collections.push({ 'blockchain': 'BSC', 'name': 'MASTERCHEF2NFTS', 'address': '0xc214811e9eb26521ebf9396d04b8e9676555fe78', 'filename': 'BSC_MASTERCHEF2NFTS', 'holders': 53 })
    collections.push({ 'blockchain': 'Polygon', 'name': 'Crypto Prophecies Item', 'address': '0xddc9c4f3c97a1dab29719d779cb68962e07691c7', 'filename': 'Polygon_CryptoPropheciesItem', 'holders': 367 })
    collections.push({ 'blockchain': 'BSC', 'name': 'Mines of Dalarnia Canary Birds Collection', 'address': '0x23ff55bccfb446864814d19eddd11fb42615d50a', 'filename': 'BSC_MinesofDalarniaCanaryBirdsCollection', 'holders': 432 })
        //Degenz List length 10000 >= 10000 I'm going to fetch them also in Descending Order: isAsc=false
    collections.push({ 'blockchain': 'Ethereum', 'name': 'Degenz', 'address': '0xfb9e9e7150ccebfe42d58de1989c5283d0eaab2e', 'filename': 'Ethereum_Degenz', 'holders': 3453 })
    collections.push({ 'blockchain': 'Fantom', 'name': 'FANTOPEPE', 'address': '0xce7ad29cf8d5e93aa7dd1e98089fd07fe09c102c', 'filename': 'Fantom_FANTOPEPE', 'holders': 13 })
    collections.push({ 'blockchain': 'BSC', 'name': 'Moonpoly', 'address': '0x9fa10a77de966e00f95a4d8c5e3b285238900058', 'filename': 'BSC_Moonpoly', 'holders': 59 })
    collections.push({ 'blockchain': 'Polygon', 'name': 'Crypto Prophecies Prophet', 'address': '0x3687da0bf6486d367f26e4b2cf071c926df65c99', 'filename': 'Polygon_CryptoPropheciesProphet', 'holders': 437 })
        //Myth NFT List length 10000 >= 10000 I'm going to fetch them also in Descending Order: isAsc=false
    collections.push({ 'blockchain': 'BSC', 'name': 'Myth NFT', 'address': '0xa011ae350081cf76c981d47f8c10e5d695f7f42b', 'filename': 'BSC_MythNFT', 'holders': 10329 })
    collections.push({ 'blockchain': 'Ethereum', 'name': 'DeRace Ticket', 'address': '0x76f359b6fbd0fad703cefd1b3665d00ece8e882c', 'filename': 'Ethereum_DeRaceTicket', 'holders': 259 })
    collections.push({ 'blockchain': 'Fantom', 'name': 'FTM CyberPunk', 'address': '0x90de496c15e2434c5799038b528b927e4036cf4b', 'filename': 'Fantom_FTMCyberPunk', 'holders': 757 })
    collections.push({ 'blockchain': 'BSC', 'name': 'Non Fungible Apes', 'address': '0x6eca7754007d22d3f557740d06fed4a031befe1e', 'filename': 'BSC_NonFungibleApes', 'holders': 692 })
        //Crypto Unicorns Market List length 10000 >= 10000 I'm going to fetch them also in Descending Order: isAsc=false
    collections.push({ 'blockchain': 'Polygon', 'name': 'Crypto Unicorns Market', 'address': '0xdC0479CC5BbA033B3e7De9F178607150B3AbCe1f', 'filename': 'Polygon_CryptoUnicornsMarket', 'holders': 3935 })
    collections.push({ 'blockchain': 'BSC', 'name': 'Non Fungible Takos', 'address': '0x60f789939167b3018192bba87638dd7f33b47f86', 'filename': 'BSC_NonFungibleTakos', 'holders': 17 })
    collections.push({ 'blockchain': 'Ethereum', 'name': 'DINOX', 'address': '0xb2dd01ee7b2c7badc9ec08c97da7ec01ac53fa29', 'filename': 'Ethereum_DINOX', 'holders': 652 })
        //Spooky Skeletoons List length 10000 >= 10000 I'm going to fetch them also in Descending Order: isAsc=false
    collections.push({ 'blockchain': 'Fantom', 'name': 'Spooky Skeletoons', 'address': '0x1dadaa7e55b2c7238ed04891ac182ea1468b79b9', 'filename': 'Fantom_SpookySkeletoons', 'holders': 605 })
        //The Sandbox List length 10000 >= 10000 I'm going to fetch them also in Descending Order: isAsc=false
    collections.push({ 'blockchain': 'Ethereum', 'name': 'The Sandbox', 'address': '0x50f5474724e0ee42d9a4e711ccfb275809fd6d4a', 'filename': 'Ethereum_TheSandbox', 'holders': 9696 })
    collections.push({ 'blockchain': 'Ethereum', 'name': 'TopDogBeachClub', 'address': '0x6f0365ca2c1dd63473f898a60f878a07e0f68a26', 'filename': 'Ethereum_TopDogBeachClub', 'holders': 3864 })
}

initCollections()

export default function getCollections() {
    return collections;
}

export function retrieveCollections(blockchain) {
    if (blockchain) {
        return collections.filter(col => col.blockchain === blockchain).sort((a, b) => String(a.name).localeCompare(b.name))
    }
    return collections.sort((a, b) => String(a.name).localeCompare(b.name))
}