import React, { useState, useEffect } from "react";
import AdsContractHelper from "../AdsContractHelper"
import { getCurrentNetwork } from "../Blockchains"
import { log } from "../debugUtils"

function AdsWelcomeMsg() {
  const [subsFee, setSubsFee] = useState(null);
  let welcomeMsg;

  useEffect(() => {
    if (getCurrentNetwork()) {
      getSubscriptionFees()
    }
  });

  const getSubscriptionFees = async () => {
    const contractHelper = new AdsContractHelper()
    await contractHelper.loadWeb3(()=>{})
    const subsFee = await contractHelper.getBatchPriceInNativeToken(10)
    log(`Setting subscriptionFee: ${subsFee} `)
    setSubsFee(subsFee)
  }

  if (getCurrentNetwork()) {
    //const token = getCurrentNetwork().token
    welcomeMsg = <div id="welcomeMsg">
      Welcome to Ads! <br></br><br></br>
      This service is provided to send multiple NFTs, with your own personalized content, to reach as many people as possible.
      Select the NFT collections you want to target from the drop-up menu, then insert the number of holders for each collection. <br></br>
      <br></br>
      📖 Important Rules:<br></br>
      - You should not send more than 500 NFTs in a single transaction.<br></br>
      - You will pay a fee for each transaction based on the number of recipients.<br></br>
      - If the transaction does not succeed, you will not be charged any fees.<br></br>
      <br></br>
      💡 Here is the simple pricing model:<br></br>
      - Up to 10 recipients you pay 0.66 MATIC<br></br>
      - Up to 50 recipients you pay 2.55 MATIC<br></br>
      - Up to 100 recipients you pay 4.55 MATIC<br></br>
      - Up to 500 recipients you pay 17.17 MATIC<br></br>
      - More than 500 recipients you pay 58.58 MATIC (⚠️ transaction would likely fail)<br></br>
    </div>
  } else {
    welcomeMsg = <div id="welcomeMsg">
      Welcome to Ads, <br></br><br></br>
      We haven't detected a Web3 compatible browser.<br></br>
      Consider using a wallet browser or an extension like Metamask.<br></br>
      <br></br>
      Then, you'll be able to join us in the metaverse 👾
    </div>
  }

  return <div id="welcomeContainer">
    {welcomeMsg}
  </div>;
}

export default AdsWelcomeMsg;

