import { getMetaData } from "./NFTMetadata";
import { create } from 'ipfs-http-client'
import { log } from "./debugUtils"

const client = create('https://ipfs.infura.io:5001/api/v0')

export async function uploadDataToIPFS(imageURL, senderAdress) {
    const imgFile = getImageFile(imageURL)
    const imgUrl = await uploadFileToIPFS(imgFile)
    log(`IMAGE IPFS: ${imgUrl}`)
    const today = new Date().toUTCString()
    const metadata = getMetaData(imgUrl, senderAdress, today)
    const metaJson = JSON.stringify(metadata)
    const metadataFile = new File([metaJson], 'metadata.json', { type: 'text/plain;charset=UTF-8' })
    const fileUrl = await uploadFileToIPFS(metadataFile)
    log(`IPFS File: ${fileUrl}`)
    return fileUrl;
}

export async function uploadFileToIPFS(file) { //TODO fallo diverso per ads
    let fileUrl;
    try {
        const added = await client.add(file)
        fileUrl = `https://ipfs.infura.io/ipfs/${added.path}`
    } catch (error) {
        log(`Error uploading file: ${error}`)
    }
    return fileUrl
}

export function getImageFile(fileContent) {
    const arr = fileContent.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    let u8arr = new Uint8Array(n)

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
    }

    const imgFile = new File([u8arr], 'filename.png', { type: mime })
    return imgFile;
}