import { log } from "./debugUtils"
import { getCurrentNetwork } from "./Blockchains"
import ContractHelper from "./ContractHelper"
import { AdsABI } from "./contracts/ABIs/AdsABI"
export default class AdsContractHelper extends ContractHelper {

    constructor() {
        super(AdsABI)
        this.isAddressWhitelisted = true
        this.FIRST_TIER = "10"
        this.SECOND_TIER = "50"
        this.THIRD_TIER = "100"
        this.FOUR_TIER = "500"
        this.MAX_BATCH_SIZE = ""
        this.FIRST_TIER_BATCH_PRICE = "~ 1 USD"
        this.SECOND_TIER_BATCH_PRICE = "~ 4 USD"
        this.THIRD_TIER_BATCH_PRICE = "~ 7 USD"
        this.FOUR_TIER_BATCH_PRICE = "~ 28 USD"
        this.MAX_BATCH_PRICE = ""
    }

    isWhitelisted = async() => {
        return this.isAddressWhitelisted;
    }

    getContractAddress = () => { return getCurrentNetwork().AdsAddress }

    onContractInitialized = async() => {
        await this.getPriceList()
    }


    calculateBatchPrice = async(batchSize) => {
        const tx = {
            'from': this.account.toString(),
            'to': this.getContractAddress(),
        };

        const batchPriceWei = await this.contract.methods.calculateBatchPrice(batchSize).call(tx)
        log(`Ads Helper - Batch Price (wei): ${batchPriceWei} for a size of: ${batchSize}`)

        const batchPriceInEth = this.web3.utils.fromWei(batchPriceWei, 'ether');
        log(`Ads Helper - Batch Price in Native Token: ${batchPriceInEth} for a size of: ${batchSize}`)

        return batchPriceWei
    }

    getPriceList = async() => {
        this.FIRST_TIER = await this.contract.methods.FIRST_TIER().call()
        this.SECOND_TIER = await this.contract.methods.SECOND_TIER().call()
        this.THIRD_TIER = await this.contract.methods.THIRD_TIER().call()
        this.FOUR_TIER = await this.contract.methods.FOUR_TIER().call()
        this.MAX_BATCH_SIZE = await this.contract.methods.MAX_BATCH_SIZE().call()

        /////// PRICES

        this.FIRST_TIER_BATCH_PRICE = `${this.web3.utils.fromWei(await this.contract.methods.FIRST_TIER_BATCH_PRICE().call(), 'ether')} ${getCurrentNetwork().token}`
        this.SECOND_TIER_BATCH_PRICE = `${this.web3.utils.fromWei(await this.contract.methods.SECOND_TIER_BATCH_PRICE().call(), 'ether')} ${getCurrentNetwork().token}`
        this.THIRD_TIER_BATCH_PRICE = `${this.web3.utils.fromWei(await this.contract.methods.THIRD_TIER_BATCH_PRICE().call(), 'ether')} ${getCurrentNetwork().token}`
        this.FOUR_TIER_BATCH_PRICE = `${this.web3.utils.fromWei(await this.contract.methods.FOUR_TIER_BATCH_PRICE().call(), 'ether')} ${getCurrentNetwork().token}`
        this.MAX_BATCH_PRICE = `${this.web3.utils.fromWei(await this.contract.methods.MAX_BATCH_PRICE().call(), 'ether')} ${getCurrentNetwork().token}`
    }

    getBatchPriceInNativeToken = async(batchSize) => {
        const tx = {
            'from': this.account.toString(),
            'to': this.getContractAddress(),
        };
        const batchPriceWei = await this.contract.methods.calculateBatchPrice(batchSize).call(tx)

        const batchPriceInEth = this.web3.utils.fromWei(batchPriceWei, 'ether');
        log(`Ads Helper - Batch Price in Native Token: ${batchPriceInEth} for a size of: ${batchSize}`)

        return batchPriceInEth
    }

    mintAds = async(fileURI, toAddresses) => {
        const batchPrice = await this.calculateBatchPrice(toAddresses.length);
        const value = this.web3.utils.toHex(batchPrice);

        const tx = {
            'from': this.account.toString(),
            'to': this.getContractAddress(),
            maxPriorityFeePerGas: null,
            maxFeePerGas: null, 
            'value': value
        };
        return await this.contract.methods.mintAds(toAddresses, fileURI).send(tx)
    }

    //TODO implement
    getNFTUri = async(id) => {
        log(`token_id: ${id}`)
        const tokenURI = await this.contract.methods.tokenURI(id).call()
        log(`tokenURI: ${tokenURI}`)
        return tokenURI
    }

}