import React, { useState, useEffect } from "react";
import AdsLogo from './AdsLogo'
import AdsWelcomeMsg from './AdsWelcomeMsg'
import Toast from './Toast'
import Networks from './Networks'
import Collections from './Collections'
import { plainTxtToHtml, calculateFontSize, drawCanvas } from "../previewUtils";
import { getCurrentNetwork } from "../Blockchains"
import AdsContractHelper from "../AdsContractHelper"
import { log } from "../debugUtils"
import { uploadDataToIPFS } from "../ipfsUtils"
import SocialNetworks from './SocialNetworks'
import { fetchHolderAddressList } from "../holders"

const previewPromptMsg = `NFT preview 📑 `
const maxTxtLength = 300000
const contractHelper = new AdsContractHelper()

function Ads() {
  const [
    state, setState,
  ] = useState({
    showButton: true,
    isWhitelisted: false
  })
  const [toast, showToast] = useState({
    showToast: false,
    toastMsg: '',
    isErrorToast: false,
  })

  useEffect(() => {
    prepareWeb3()
  }, []);

  const prepareWeb3 = async () => {
    const web3 = await contractHelper.loadWeb3(() => { verifySubscription() })
    if (!web3) {
      showToast({ showToast: true, toastMsg: 'Non-Web3 browser detected. Please use a Web3 compatible browser!', isErrorToast: true })
      setState({ isWhitelisted: false, showButton: false })
    }
  }
  //TODO do we nedd this fucntion?
  const verifySubscription = async () => {
    const currentNetwork = getCurrentNetwork()
    log(`Verirying subscription for Network: ${JSON.stringify(currentNetwork)}`)

    if (currentNetwork) {
      const isWhitelisted = await contractHelper.isWhitelisted()

      setState({
        isWhitelisted: isWhitelisted,
        showButton: true,
      })

    } else {
      showToast({ showToast: true, toastMsg: 'Unsupported Network, please change blockchain on your wallet', isErrorToast: true })
    }
  }

  const onTxtChanged = async (e) => {
    const textArea = e.target;
    const htmlText = plainTxtToHtml(textArea.value)

    let msgPreview = document.getElementById('adsMsgPreview');
    msgPreview.innerHTML = htmlText.length > 0 ? htmlText : previewPromptMsg;

    const fontSize = calculateFontSize(msgPreview)
    msgPreview.style.fontSize = `${fontSize}px`
  }

  const onSend = async (e) => {
    const addressList = await fetchHolderAddressList();

    if(addressList.length < 1){
      console.log("You should select at least 1 target address")
      showToast({ showToast: true, toastMsg: "You should select at least 1 target address", isErrorToast: true })
      return
    }

    const areAddressesValid = validateAdressess(addressList);

    if (areAddressesValid) {
      console.log(`Sending Ads to ${addressList.length} addresses`)
      disableButton()
      const msgPreview = document.getElementById('adsMsgPreview')
      const canvas = await drawCanvas(msgPreview)
      const fileUrl = await uploadDataToIPFS(canvas.toDataURL("image/png"), contractHelper.getAccount())
      await mintNFTAds(fileUrl, addressList);
    }
  }

  function validateAdressess(addressList) {
    addressList.forEach(address => {
      if (!contractHelper.validateAddress(address + '')) {
        console.warn(`Invalid Target address: ${address}`);
        showToast({ showToast: true, toastMsg: `Invalid Target address: ${address}. Please insert a valid comma separated address list`, isErrorToast: true });
        return false;
      }
    });
    return true;
  }

  async function mintNFTAds(fileUrl, addressList) {
    try {
      await contractHelper.mintAds(fileUrl, addressList);
      showToast({ showToast: true, toastMsg: "NFT Ads sent!", isErrorToast: false });
    } catch (e) {
      log(`Transaction Error: ${e}`, 'error');
      showErrorToast(e);
    } finally {
      enableButton();
    }
  }

  const showErrorToast = (e) => {
    if (e.code === 4001) {
      showToast({ showToast: true, toastMsg: e.message, isErrorToast: true })
    } else {
      showToast({ showToast: true, toastMsg: "Transaction failed, please check your wallet balance", isErrorToast: true })
    }
  }

  const enableButton = async () => {
    const myButton = document.getElementById('myButton')
    myButton.setAttribute("class", "SendButton")
    myButton.disabled = false
  }

  const disableButton = async () => {
    const myButton = document.getElementById('myButton')
    myButton.setAttribute("class", "gradiend")
    myButton.disabled = true
    return myButton
  }

  let FromAddress, TextArea, MsgPreview, Button;


  MsgPreview = <div id="previewContainer"><div id="adsMsgPreview">
          
      Welcome to Ads! <br></br><br></br>
      We allow you to send custom NFT messages to several <br></br>
      proved NFT lovers which are top collections holders.<br></br>
      <br></br>
      💡 In four easy steps: <br></br><br></br>
        1) Prepare your NFT message.<br></br>
        2) Pick the target collections from the dropUp menu.<br></br>
        3) Set the holders range for each collection.<br></br>
        4) Hit the send button and chill! 😎<br></br>
      <br></br>
      Here is our fair pricing model 👇🏾<br></br>
      <br></br>
+-----------------------------------------------+<br></br>
| > Up to {contractHelper.FIRST_TIER} recipients you'll pay {contractHelper.FIRST_TIER_BATCH_PRICE} <br></br>
+-----------------------------------------------+<br></br>
| > Up to {contractHelper.SECOND_TIER} recipients you'll pay {contractHelper.SECOND_TIER_BATCH_PRICE} <br></br>
+-----------------------------------------------+<br></br>
| > Up to {contractHelper.THIRD_TIER} recipients you'll pay {contractHelper.THIRD_TIER_BATCH_PRICE} <br></br>
+-----------------------------------------------+<br></br>
| > Up to {contractHelper.FOUR_TIER} recipients you'll pay {contractHelper.FOUR_TIER_BATCH_PRICE} <br></br>
+-----------------------------------------------+<br></br>   
    </div></div>

  FromAddress = <div><b>From(you)</b>: {contractHelper.getAccount()}</div>
  const placeholder = `Write or paste your message here.
Remember you can use emoji, some html tags or even ascii art.`
  TextArea = <textarea className="TextArea" id="the_text" maxLength={maxTxtLength} spellCheck="false" placeholder={placeholder} onChange={onTxtChanged} />
  //MsgPreview = <AdsWelcomeMsg></AdsWelcomeMsg>
  //MsgPreview = <div id="previewContainer"><div id="msgPreview">{welcomeMsg}</div></div>
  Button = <button id='myButton' className="SendButton" onClick={onSend}>Send</button>

  //TODO move this from here
  if (toast.showToast) {
    setTimeout(() => { showToast({ showToast: false }) }, 5555);
  }

  //document.documentElement.style.setProperty('--main-color', 'black');
  //document.documentElement.style.setProperty('--second-color', '#f8f8f8');

  document.documentElement.style.setProperty('--main-color', '#f8f8f8');
  document.documentElement.style.setProperty('--second-color', 'black');
  document.documentElement.style.setProperty('--shadow-color', '#c8c8c8');

  return (
    <div className="App">
      <AdsLogo></AdsLogo>
      <Networks></Networks>
      {MsgPreview}
      <Toast msg={toast.toastMsg} show={toast.showToast} isError={toast.isErrorToast}></Toast>
      {TextArea}

      <Collections />

      <div className="containerAds">
        <div className="clientInfoAds">
          {FromAddress}
        </div>
        {Button}
      </div>
      <SocialNetworks></SocialNetworks>
      <div className="version">{process.env.REACT_APP_VERSION}</div>
    </div>
  )
}

export default Ads
