import React from 'react'
import { SocialIcon } from 'react-social-icons';

const socialsStyle = {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 'auto',
    /*width: '33%',*/
    maxWidth: '754px',
}

const iconStyle = {
    width: '36px',
    height: '36px'
}

const socialNetworks = [
    { name:'Twitter', url: "https://twitter.com/Nftchatxyz"},
    { name:'Telegram', url:"https://t.me/joinchat/yVwxIcb7vZA3NGQ8"},
    { name:'Discord', url: "https://discord.gg/Tudptmvm"},
    { name:'OpenSea', url: "https://opensea.io/collection/nftchat"}
];

function SocialNetworks() {
    return (
        <div style={socialsStyle}>
            {socialNetworks.map(net => { return <a key={net.name} href={net.url} target='_blank' rel='noopener noreferrer'>{net.name}</a> })}
        </div>
    )
}

function SocialNetworksLogo() {
    return (
        <div style={socialsStyle}>
            {socialNetworks.map(net => { return <SocialIcon style={iconStyle} url={net.url}  fgColor='black' bgColor='#00fd00'></SocialIcon> })}
        </div>
    )
}

export default SocialNetworks;
