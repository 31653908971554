import collectors from './collections/holders/collectors'
import { log } from "./debugUtils"

const MAX_ADDRESS_NUMBER = 500;
let selectedHolders = [];

export function getMaxAddressNumber() {
    return MAX_ADDRESS_NUMBER;
}

export function getHolders() {
    return selectedHolders;
}

export function setHolders(_selectedHolders) {
    selectedHolders = _selectedHolders;
}

export async function fetchHolderAddressList() {

    let addressList = [];
    for (const col of selectedHolders) {
        const holders = collectors.get(col.fileName)
        log(`HOLDERS IMPORT of ${col.fileName} : {holders}`)

        const selected = holders.slice(col.from, col.to);
        addressList = addressList.concat(selected)
        if (addressList.length > MAX_ADDRESS_NUMBER) {
            console.warn(`target address > ${MAX_ADDRESS_NUMBER} transaction will likely fail`)
                //addressList = addressList.slice(0, MAX_ADDRESS_NUMBER);
                //break
        }
    }
    log(`Addresses List: ${addressList}`)
    return addressList;
}