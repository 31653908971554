import React, { useState, useEffect } from "react";

const infoStyle = { display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingLeft:'2%', paddingRight: '2%', marginLeft: 'auto', marginRight: 'auto', padding: '1%', backgroundColor: '#00f907', color: 'black', maxWidth: '562px', width: '80%', fontFamily: 'monospace', fontSize: '1.5vmin' };
const errorStyle = { display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingLeft:'2%', paddingRight: '2%', marginLeft: 'auto', marginRight: 'auto', padding: '1%', backgroundColor: '#db8300', color: 'black', maxWidth: '562px', width: '80%', fontFamily: 'monospace', fontSize: '1.5vmin' };

function Toast(props) {
  const [show, setShow] = useState(false);
  const shouldShow = props.show;

  useEffect(() => { setShow(shouldShow) }, [shouldShow]);

  if (show) {
    let toast;
    if (props.isError) {
      toast = <div style={errorStyle}>        
        <p>{props.msg}</p>
        <span className="closebtn" onClick={() => setShow(false)}>x</span>
      </div>
    } else {
      toast = <div style={infoStyle}>
        <p>{props.msg}</p>
        <span className="closebtn info" onClick={() => setShow(false)}>x</span>
      </div>;

    }
    return toast;
  }
  return null;
}

export default Toast;


