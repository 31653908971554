import React from "react";

function AdsLogo() {
    return <div className="AdsTitle">
___________███████__________███___________________<br></br>
__________█████████_________███___________________<br></br>
_________███_____███________███___________________<br></br>
_________███_____███___████████__████████_________<br></br>
_________███████████__███___███__███______________<br></br>
_________███████████__███___███__████████_________<br></br>
_________███_____███__███___███_______███_________<br></br>
_________███_____███___████████__████████__<a style={{margin:0, padding:1}} href="https://nftchat.xyz/" target="_blank" rel="noreferrer">by NFTchat</a>_<br></br>
  </div>;
}

export default AdsLogo;

