import React, { useState, useEffect } from "react";
import NFTchatLogo from './NFTchatLogo'
import Toast from './Toast'
import Networks from './Networks'
import { getCurrentNetwork } from "../Blockchains"
import ChatContractHelper from "../ChatContractHelper"
import { getAccountTokenIds } from "../messages"
import { log } from "../debugUtils"
import SocialNetworks from './SocialNetworks';

const contractHelper = new ChatContractHelper()

function Visualizer() {
  const [
    state, setState,
  ] = useState({
    nfts: []
  })
  const [toast, showToast] = useState({
    showToast: false,
    toastMsg: '',
    isErrorToast: false,
  })

  useEffect(() => {
    prepareWeb3()
  }, []);

  const prepareWeb3 = async () => {
    const web3 = await contractHelper.loadWeb3(() => { verifySubscription() })
    if (!web3) {
      showToast({ showToast: true, toastMsg: 'Non-Web3 browser detected. Please use a Web3 compatible browser!', isErrorToast: true })
    }
  }

  const verifySubscription = async () => {
    const currentNetwork = getCurrentNetwork()
    log(`Verirying suubscription for Network: ${JSON.stringify(currentNetwork)}`)

    if (currentNetwork) {
      const account = contractHelper.getAccount();
      const tokenIds = await getAccountTokenIds(account);
      let tokensInfo = [];
      log(`tokenIds: ${tokenIds}`)


      for (let index = 0; index < tokenIds.length; index++) {
        const nftId = tokenIds[index];
        const nftUri = await contractHelper.getNFTUri(nftId)
        const response = await fetch(nftUri)
        const tokeInfo = await response.json()
        //log(`tokeInfo: ${JSON.stringify(tokeInfo)}`)
        tokensInfo.push(tokeInfo)
      }
      
        setState({
          nfts: tokensInfo
        })
    } else {
      showToast({ showToast: true, toastMsg: 'Unsupported Network, please change blockchain on your wallet', isErrorToast: true })
    }
  }


  const imgStyle = {
    width: "400px",
    height: "400px",
    margin: 'auto'
  };


  //TODO move this from here
  if (toast.showToast) {
    setTimeout(() => { showToast({ showToast: false }) }, 5555);
  }

  document.documentElement.style.setProperty('--main-color', '#00fd00');
  document.documentElement.style.setProperty('--second-color', 'black');
  document.documentElement.style.setProperty('--shadow-color', '#c8c8c8');


  let myKey=0

  return (
    <div className="App">
      <NFTchatLogo></NFTchatLogo>
      <Networks></Networks>
      <Toast msg={toast.toastMsg} show={toast.showToast} isError={toast.isErrorToast}></Toast>
      
      {state.nfts.map(nft => {
        return (
          <div>
          <img style={imgStyle} src={nft.image} key={myKey++} alt={nft.description}></img>
          <div style={imgStyle} key={myKey++} >{nft.description}</div>
          </div>
        )
      })}     

      <SocialNetworks></SocialNetworks>      
      <div className="version">{process.env.REACT_APP_VERSION}</div>
    </div>
  )
}

export default Visualizer
