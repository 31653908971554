import { getCurrentNetwork } from "./Blockchains"

const result = {
    "status": "1",
    "message": "OK-Missing/Invalid API Key, rate limit of 1/5sec applied",
    "result": [{
        "blockNumber": "11406705",
        "timeStamp": "1633132130",
        "hash": "0xaab3f3d212a0c89dcecc0d967022bc70d64da5851b7ce238d3fc6aa7e8a566ed",
        "nonce": "3",
        "blockHash": "0xbd35194d11639486cccc68f2f0acdc1f5c2372f7e0ad8b8b8bb3eec9d6bf1991",
        "from": "0x0000000000000000000000000000000000000000",
        "contractAddress": "0x801421ff921b2343bec33ecb0a6b6aed58e641a2",
        "to": "0xfc9cdedf726632f95a45b8a27b63aa115bcb0bf6",
        "tokenID": "1",
        "tokenName": "NFTchat",
        "tokenSymbol": "CHAT",
        "tokenDecimal": "0",
        "transactionIndex": "131",
        "gas": "264333",
        "gasPrice": "5000000000",
        "gasUsed": "176222",
        "cumulativeGasUsed": "21015266",
        "input": "deprecated",
        "confirmations": "168406"
    }]
}

let response;

export async function getAccountTokenIds(accountAddress) {
    const currentNetwork = getCurrentNetwork();
    const scanAPI = `${currentNetwork.scanBaseUrl}contractaddress=${currentNetwork.NFTchatAddress}&address=${accountAddress}`

    console.log(`Getting TOKENS from: ${scanAPI}`)

    if (!response && scanAPI != '') {
        response = await fetch(scanAPI)
            //fetch("https://api.bscscan.com/api?module=account&action=tokennfttx&contractaddress=0x801421fF921b2343BeC33EcB0a6B6AED58E641A2&address=0xFC9cDeDf726632f95a45B8a27b63Aa115BCB0Bf6&page=1&offset=100&sort=asc")
            .then(res => res.json())
            .then(
                (result) => {
                    console.log(`RESULT ${JSON.stringify(result)}`)
                    const tokenList = result.result;
                    console.log(`RESULT tokenList ${JSON.stringify(tokenList)}`)
                    console.log(`tokenList length ${tokenList.length}`)
                        //const last = tokenList.length - 1;

                    const tokenIds = tokenList.map(token => token.tokenID)
                    console.log(`tokenIds ${tokenIds}`)

                    const last = 0
                    response = tokenList[last].tokenID //FIXME this is temp test
                    return tokenIds
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    console.error(`error ${error}`)
                }
            )
        return await response
    } else {
        return response
    }

}