//const EthereumMainnet = { "name": 'Ethereum', 'token': 'ETH', "contractAddress": '', "scanBaseUrl": '', "fee": "0" };
const Polygon = { "name": 'Polygon', 'token': 'MATIC', "NFTchatAddress": '0xd52147d30cecdd745aba5e3943e00724b194d539', "AdsAddress": '0x4680ea0153DeE569686DA393fF80d28A14E9cBaF', "scanBaseUrl": 'https://api.polygonscan.com/api?module=account&action=tokennfttx&page=1&offset=10000&sort=desc&', "fee": '0' };
const BinanceSmartChain = { "name": 'BSC', 'token': 'BNB', "NFTchatAddress": '0x801421fF921b2343BeC33EcB0a6B6AED58E641A2', "AdsAddress": '0xFe1358BF982d39eBf09c070ff8466A9849fCa584', "scanBaseUrl": 'https://api.bscscan.com/api?module=account&action=tokennfttx&page=1&offset=10000&sort=desc&', "fee": '0' };
const Fantom = { "name": 'Fantom', 'token': 'FTM', "NFTchatAddress": '0x3b0c0CF75b4335371Ec255576BBbE191178f11c6', "AdsAddress": '0xfd6756ae1907EAe1555c685CE5A7e775C9FBF1E3', "scanBaseUrl": 'https://api.ftmscan.com/api?module=account&action=tokennfttx&page=1&offset=10000&sort=desc&', "fee": '0' };

const supportedNetworks = new Map();
let currentNetwork;
initNetworks()

export function getNetworks() {
    return supportedNetworks;
}

export function getAdsOnlyNetworks() {
    const adsNetworks = new Map(
        [...supportedNetworks]
        .filter(([k, v]) => v.AdsAddress)
    )
    console.log("Ads Blockhains: ", adsNetworks)
    return adsNetworks;
}

export function setCurrentNetwork(networkId) {
    currentNetwork = supportedNetworks.get(networkId);
    if (!currentNetwork || !currentNetwork.NFTchatAddress) {
        return null;
    }
    return currentNetwork;
}

export function getCurrentNetwork() {
    return currentNetwork;
}

function initNetworks() {
    //MAIN NETS
    //supportedNetworks.set(1, EthereumMainnet);
    supportedNetworks.set(137, Polygon);
    supportedNetworks.set(56, BinanceSmartChain);
    supportedNetworks.set(250, Fantom);
    //TEST NETS

    //FXME
    if (process.env.REACT_APP_DEBUG === 'true') {
        supportedNetworks.set(3, { "name": 'Ropsten', 'token': 'ETH', "NFTchatAddress": '0xd52147d30ceCDD745ABA5E3943E00724b194d539', "AdsAddress": '0xCf9Be728206aF3a52d7Fe7d39103BD7095d482C0', "scanBaseUrl": '', "fee": "0" });
        supportedNetworks.set(80001, { "name": 'Mumbai', 'token': 'MATIC', "NFTchatAddress": '0xCF3Af95b3539711e8dc656Bd83257E65F9182E5C', "AdsAddress": '0x03E1e06a56097b0E6a9243623429237b34286D8D', "scanBaseUrl": '', "fee": '0' });
        supportedNetworks.set(97, { "name": 'BSC_Testnet', 'token': 'BNB', "NFTchatAddress": '0x82267381d9DedAd6CB3D59a24BE79cB14D8DbB50', "AdsAddress": '0x6699D91c8Ccec10586982BFa26d76fE084535BBA', "scanBaseUrl": '', "fee": '0' });
        supportedNetworks.set(4002, { "name": 'Fantom_Testnet', 'token': 'FTM', "NFTchatAddress": '0xf1bcad175dfac737dac5fc7176c516d91126f0cb', "AdsAddress": '0x5d8FFF4e34Ed2063b6cc7F0385973493bb00aD74', "scanBaseUrl": '', "fee": '0' });
    }
    //default is the first one
    currentNetwork = supportedNetworks[0];
}