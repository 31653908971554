import Web3 from 'web3'
import { log } from "./debugUtils"
import { setCurrentNetwork } from "./Blockchains"
export default class ContractHelper {

    constructor(contractABI) {
        this.contractABI = contractABI
    }

    loadWeb3 = async(callback) => {
        if (!this.web3) {
            if (window.ethereum) {
                window.web3 = new Web3(window.ethereum)
                await window.ethereum.enable()
            } else if (window.web3) {
                window.web3 = new Web3(window.web3.currentProvider)
            } else {
                console.error(`Non-Web3 browser detected. Please use a Web3 compatible browser!'`)
                return
            }

            await window.web3.currentProvider.enable();
            this.web3 = new Web3(window.web3.currentProvider);
            this.subscribeToNetworkEvents(callback);
        }
        await this.initContract()
        callback()
        return this.web3
    }

    initContract = async() => {
        const networkId = await this.web3.eth.net.getId()
        log(`ContractHelper - INIT CONTRACT for networkId: ${networkId}`)
        const currentNetwork = setCurrentNetwork(networkId);

        if (currentNetwork) {
            log(`ContractHelper - Current Network: ${JSON.stringify(currentNetwork)}`)
            log(`Mint fee: ${currentNetwork.fee}`) //TODO get it from contract

            const accounts = await this.web3.eth.getAccounts()
            this.account = accounts[0]
            log(`ContractHelper - Contract Address: ${this.getContractAddress()}`)
            this.contract = await new this.web3.eth.Contract(this.contractABI, this.getContractAddress())
        }
        await this.onContractInitialized()
    }

    onContractInitialized = async() => {} //abstract function

    getContractAddress = () => { throw new Error('You have to implement getContractAddress function!'); }

    getAccount = () => {
        console.log(`Getting account: ${this.account}`)
        return this.account
    }

    validateAddress = (address) => {
        return this.web3.utils.isAddress(address)
    }

    subscribeToNetworkEvents(callback) {
        if (window.web3) {
            window.ethereum.on('chainChanged', async(networkId) => {
                log(`chainChanged to networkId: ${networkId}`);
                await this.initContract();
                callback();
            });

            window.ethereum.on('accountsChanged', async(accounts) => {
                log("accountsChanged");
                await this.initContract();
                callback();
            });
        }
    }

}